import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import '../map/Map.scss';
import '../map/notes/form/Notes.scss';
import { useTranslation } from 'react-i18next';
import { Map as MapLeaflet, Viewport } from 'react-leaflet';
import L, { LeafletMouseEvent } from 'leaflet';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import { makeStyles } from '@mui/styles';
import useLoadingMapData from '@app/modules/map-module/hooks/useLoadingMapData';
import useViewportMap from '@app/modules/map-module/hooks/useViewportMap';
import LayersControl from '@app/modules/map-module/components/map/layers-control/LayersControl';
import 'leaflet-geosearch/dist/geosearch.css';
import CustomMarker from '@app/modules/map-module/components/map/custom-marker/CustomMarker';
import BadgeMenuIconMap from '@app/components/common/badge-menu-icon-map/BadgeMenuIconMap';
import RoadMapping from '@app/modules/map-module/components/map/road-mapping/road-mapping';
import { reverseGeocode } from '@app/v2/shared/helpers';
import { useActions, useAppDispatch, useAppSelector } from '@app/v2/shared/hooks';

type Props = {
  isPositionLoaded: boolean;
  position: {
    latitude: number;
    longitude: number;
  };
  setPosition: Common.AnyFunction;
};

const ListingMapComponent = ({ isPositionLoaded, position, setPosition }: Props) => {
  const classes = useStyles();

  const dispatch = useAppDispatch();

  const { t } = useTranslation('components');

  const { setViewport } = useActions();

  const [loading, setLoading] = useState<boolean>(true);

  const viewport = useAppSelector(state => state.map.viewport);

  const mapNotesCursor = useAppSelector(state => state.map.mapNotesCursor);

  const map = useRef<L.Map>();

  const isLoadingStartData = useLoadingMapData();

  useViewportMap(map);

  const setViewportChanged = useCallback(
    (data: Viewport) => {
      if (data.center && data.zoom) {
        dispatch(setViewport(data));
      }
    },
    [dispatch, setViewport],
  );

  const Search = () => {
    useEffect(() => {
      const searchControl = GeoSearchControl({
        provider: new OpenStreetMapProvider(),
        style: 'bar',
        showMarker: false,
        searchLabel: 'поиск',
      });

      map?.current?.addControl(searchControl);
      return () => {
        map?.current?.removeControl(searchControl);
      };
    }, []);

    return null;
  };

  useLayoutEffect(() => {
    const addNoteHandler = async (e: LeafletMouseEvent) => {
      const {
        latlng: { lat: latitude, lng: longitude },
      } = e;
      const address = await reverseGeocode(latitude, longitude);
      setPosition({ latitude, longitude, address });
    };

    if (!loading) {
      map?.current?.addEventListener('click', addNoteHandler);
    }
    return () => {
      map?.current?.removeEventListener('click', addNoteHandler);
    };
  }, [position, mapNotesCursor, setPosition, loading]);

  const onDragend = useCallback(
    ({
      target: {
        _latlng: { lat: latitude, lng: longitude },
      },
    }) => {
      setPosition({ latitude, longitude });
    },
    [setPosition],
  );

  const Marker = () =>
    useMemo(
      () =>
        isPositionLoaded && (
          <CustomMarker draggable position={[position?.latitude, position?.longitude]} className="mapNotes" onDragend={onDragend}>
            <BadgeMenuIconMap id="mapNote" title={t('mapNoteIcon')} items={[]} onClick={() => false} />
          </CustomMarker>
        ),
      [],
    );

  const refCallback = ref => {
    if (ref && ref.leafletElement) {
      setLoading(false);
      map.current = ref.leafletElement;
    }
  };

  return (
    !isLoadingStartData && (
      <MapLeaflet
        className={classes.map}
        ref={refCallback}
        viewport={viewport}
        onViewportChanged={setViewportChanged}
        style={{ cursor: mapNotesCursor && 'crosshair' }}
        attributionControl={false}
      >
        <LayersControl />
        <Search />
        <Marker />
        <RoadMapping />
      </MapLeaflet>
    )
  );
};

const useStyles = makeStyles(() => ({
  map: {
    position: 'static',
    height: '65vh',
    width: '100%',
  },
}));

export default ListingMapComponent;
