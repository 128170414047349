import React, { useState, useEffect, useRef, ElementType } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, DialogContent, Typography, Link, Grid, Icon, Theme, Box, Tooltip, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import { FULL_WIDTH } from '@app/v2/shared/constants';
import { getBackgroundColorForListing } from '@app/v2/shared/helpers';
import ImagesViewer from './NoteImagesViewer';

type Props = {
  title: string;
  message: string;
  address?: string;
  roadAddress?: string;
  date?: string;
  time?: string;
  photo?: any;
  organization?: string;
  user?: string;
  addressIcon?: string;
  roadIcon?: string;
  dateIcon?: string;
  timeIcon?: string;
  messageClassName?: string;
  buttonClassName?: string;
  visibilityBox?: string;
  UpdateNoteButtonComponent?: ElementType;
  updateNoteButtonProps?: any;
};

const MessageWithReadMore = ({
  messageClassName,
  buttonClassName,
  message,
  title,
  address,
  roadAddress,
  date,
  time,
  photo,
  organization,
  user,
  addressIcon,
  roadIcon,
  dateIcon,
  timeIcon,
  visibilityBox,
  UpdateNoteButtonComponent,
  updateNoteButtonProps,
}: Props) => {
  const { t } = useTranslation('map', { keyPrefix: 'notes' });

  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const messageRef = useRef<HTMLDivElement>(null);

  const classes = useStyles();

  useEffect(() => {
    const handleResize = () => {
      if (messageRef.current) {
        const isOverflow = messageRef.current.scrollHeight > messageRef.current.clientHeight;
        setIsOverflowing(isOverflow);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [message]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <div ref={messageRef} className={messageClassName}>
        {message}
      </div>
      <div style={{ minHeight: '1.125rem' }}>
        {isOverflowing && (
          <Link component="button" className={buttonClassName} onClick={() => handleOpenDialog()}>
            {t('explore')}
          </Link>
        )}
      </div>
      <Dialog open={openDialog} onClose={() => handleCloseDialog()} aria-labelledby="read-more-dialog-title" fullWidth maxWidth="xs">
        <DialogTitle id="read-more-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid item xs={10.4}>
              {title}
            </Grid>
            <Grid item xs={1.6}>
              {UpdateNoteButtonComponent && <UpdateNoteButtonComponent {...updateNoteButtonProps} />}
              <IconButton aria-label="close" onClick={() => handleCloseDialog()} className={classes.closeIconStyle}>
                <Icon>{icons.close}</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ overflow: 'hidden' }}>
          <Grid container>
            <Grid item xs={12} pb={1}>
              <Typography>{message}</Typography>
            </Grid>
            <Grid item xs={6} pb={1} className={classes.iconWithText}>
              <Icon className={classes.icon}>{addressIcon}</Icon>
              <Typography className={classes.blueText}>{address}</Typography>
            </Grid>
            <Grid item xs={6} pb={1} className={classes.iconWithText}>
              <Icon className={classes.icon}>{roadIcon}</Icon>
              <Typography className={classes.blueText}>{roadAddress}</Typography>
            </Grid>
            <Grid item xs={6} pb={1} className={classes.iconWithText}>
              <Icon className={classes.icon}>{dateIcon}</Icon>
              <Typography className={classes.blueText}>{date}</Typography>
            </Grid>
            <Grid item xs={6} pb={1} className={classes.iconWithText}>
              <Icon className={classes.icon}>{timeIcon}</Icon>
              <Typography className={classes.blueText}>{time}</Typography>
            </Grid>
            <Grid pb={1}>
              <ImagesViewer images={photo} />
            </Grid>
            <Grid item xs={12} pb={1}>
              <Typography className={classes.greyText}>{organization}</Typography>
            </Grid>
            <Grid item xs={12} pb={1}>
              <Typography className={classes.greyText}>{user}</Typography>
            </Grid>
            <Grid container pt={1}>
              <Tooltip title={`${t('form.noteVisibility')}: ${t(`form.visibilitySelect.${visibilityBox}` as any)}`} placement="top">
                <Box width={FULL_WIDTH} sx={{ bgcolor: getBackgroundColorForListing(visibilityBox) }} className={classes.visibilityBox} />
              </Tooltip>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  icon: {
    '&&': {
      verticalAlign: 'text-top',
      marginRight: '0.1rem',
      color: theme.palette.primary.main,
      fontSize: '1rem',
    },
  },
  visibilityBox: {
    '&&': {
      paddingTop: '0.5rem',
      borderRadius: '0.375rem',
    },
  },
  greyText: {
    '&&': {
      color: theme.palette.grey[600],
    },
  },
  blueText: {
    '&&': {
      color: theme.palette.info.main,
    },
  },
  iconWithText: {
    '&&': {
      display: 'flex',
      alignItems: 'top',
    },
  },
  closeIconStyle: {
    '&&': {
      color: theme.palette.info.main,
    },
  },
}));

export default MessageWithReadMore;
