import moment from 'moment';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import PRIVACY from '@app/core/constants/visibility';

const values: MapNoteInput & { roadAddress: string } = {
  title: null,
  dateTime: moment().format(DATE_FORMAT.WITH_TIME_ZONE),
  roadId: null,
  message: null,
  photos: [],
  place: {
    id: undefined,
    kilometer: null,
    meter: null,
    latitude: null,
    longitude: null,
  },
  roadAddress: null,
  visibility: PRIVACY[3].value,
  address: null,
};

export default values;
