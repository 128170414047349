import FETCH_VMS_STATIONS from './fetchVmsStations.graphql';
import FETCH_VMS_STATIONS_MANAGEMENT from './fetchVmsStationsManagement.graphql';
import FETCH_VMS_TEMPLATE_CONFIG from './fetchVmsTemplateConfig.graphql';
import FETCH_VMS_TEMPLATES from './fetchVmsTemplates.graphql';

const vmsQueries = {
  vmsStations: FETCH_VMS_STATIONS,
  vmsStationsManagement: FETCH_VMS_STATIONS_MANAGEMENT,
  vmsTemplateConfig: FETCH_VMS_TEMPLATE_CONFIG,
  vmsTemplates: FETCH_VMS_TEMPLATES,
};

export default vmsQueries;
