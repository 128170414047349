import { useCallback, useMemo } from 'react';
import { useQueryParams } from 'use-query-params';
import { AmountCounters, LISTING_DEFAULT_PAGE_NUMBER, URLParametersMapper, URLSetterChecker } from '@app/v2/shared/constants';
import useActions from '../reactRedux/useActions';
import useAppSelector from '../reactRedux/useAppSelector';

export default function useNewFilters() {
  const filters = useAppSelector(state => state.newFilters.filters);
  const isEnabled = useAppSelector(state => state.newFilters.isEnabled);

  const { addFilter, setFilter, setFilters, addFilters, toggleFiltersEnabledState, resetFilters, removeFilters } = useActions();

  const [, setParamsFilter] = useQueryParams(
    Object.entries(filters).reduce<Record<string, any>>((acc, [filterName, filter]) => {
      acc[filterName] = URLParametersMapper[filter.type];
      return acc;
    }, {}),
  );

  const { filtersValues, filtersAmount, isFiltersExist } = useMemo<Filters.Data>(
    () =>
      Object.entries(filters).reduce<Filters.Data>(
        (acc, [filterName, filter]) => {
          acc.filtersValues[filterName] = filter.value;
          acc.isFiltersExist = URLSetterChecker[filter.type](filter.value);
          if (filter?.isCounted) acc.filtersAmount += AmountCounters[filter.type](filter.value);

          return acc;
        },
        { filtersValues: {}, filtersAmount: 0, isFiltersExist: false },
      ),
    [filters],
  );

  const handleResetFilters = useCallback(() => {
    setParamsFilter(currentParameters => ({
      page: LISTING_DEFAULT_PAGE_NUMBER,
      ...Object.keys(currentParameters).reduce<Record<string, undefined>>((acc, key) => {
        acc[key] = undefined;
        return acc;
      }, {}),
    }));

    resetFilters();
  }, [resetFilters, setParamsFilter]);

  const setFiltersToURL = useCallback(
    (nextValue: Filters.Filter = {}) => {
      setParamsFilter({
        page: LISTING_DEFAULT_PAGE_NUMBER,
        ...Object.entries({ ...filters, ...nextValue }).reduce<Filters.Values>((acc, [filterName, filter]) => {
          if (URLSetterChecker[filter.type](filter.value)) acc[filterName] = filter.value;
          else acc[filterName] = undefined;

          return acc;
        }, {}),
      });
    },
    [filters, setParamsFilter],
  );

  return {
    isFiltersExist,
    isEnabled,
    filters: filtersValues,
    addFilter,
    addFilters,
    setFilter,
    setFilters,
    toggleFiltersEnabledState,
    resetFilters: handleResetFilters,
    filtersAmount,
    setFiltersToURL,
    removeFilters,
  };
}
