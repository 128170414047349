import React from 'react';
import CSDStation from './CSDStation';
import { CSDStationItem } from '../../types';
import { DEFAULT_STATION_SIZE } from '../../constants';

type Props = {
  validStation: CSDStationItem[];
  wrongStations: CSDStationItem[];
  activeStationId: number | string;
};

const CSDStationsLists = ({ validStation = [], wrongStations = [], activeStationId }: Props) => {
  return (
    <>
      {validStation.map(({ position, direction, stationId, hasHeighbor, isDublicate, isExpired, onChange }) => (
        <CSDStation
          key={stationId}
          stationId={stationId}
          position={position}
          direction={direction}
          isActive={activeStationId === stationId}
          isExpired={isExpired}
          isDublicate={isDublicate}
          hasHeighbor={hasHeighbor}
          onChange={onChange}
        />
      ))}
      <foreignObject
        width="100%"
        height={DEFAULT_STATION_SIZE}
        y={`calc(100% - ${DEFAULT_STATION_SIZE}px)`}
        x={`calc(50% - ${(DEFAULT_STATION_SIZE * wrongStations.length) / 2}px)`}
      >
        {wrongStations.map(({ position, direction, stationId, hasHeighbor, isDublicate, isExpired, onChange }) => (
          <CSDStation
            key={stationId}
            stationId={stationId}
            position={position}
            direction={direction}
            isActive={activeStationId === stationId}
            isDublicate={isDublicate}
            hasHeighbor={hasHeighbor}
            isExpired={isExpired}
            onChange={onChange}
          />
        ))}
      </foreignObject>
    </>
  );
};

export default CSDStationsLists;
