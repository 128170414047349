import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, Button, Stack } from '@mui/material';

type Props = {
  onSave: () => void;
  onReset: () => void;
};

const CSDSettingActions = ({ onSave, onReset }: Props) => {
  const { t: tooltipT } = useTranslation('video');
  const { t: buttonsT } = useTranslation('common', { keyPrefix: 'buttons' });

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-end">
      <Tooltip title={tooltipT('tooltips.buttons.reset')} placement="top" sx={{ marginRight: '1rem' }}>
        <Button variant="outlined" onClick={onReset}>
          {buttonsT('reset')}
        </Button>
      </Tooltip>
      <Tooltip title={tooltipT('tooltips.buttons.save')} placement="top">
        <Button color="primary" onClick={onSave}>
          {buttonsT('save')}
        </Button>
      </Tooltip>
    </Stack>
  );
};

export default CSDSettingActions;
