import React, { SVGProps } from 'react';
import { Tooltip, Typography, TypographyProps, Box, BoxProps } from '@mui/material';
import { TEXT_FONT_SIZE } from '../constants';

type Props = {
  label: string;
  typogrphyProps?: TypographyProps;
  containerProps?: BoxProps;
} & SVGProps<SVGForeignObjectElement>;

const CSDForignSvgLabel = ({ label, typogrphyProps, containerProps, ...other }: Props) => {
  return (
    <foreignObject {...other}>
      <Box {...containerProps}>
        <Tooltip title={label}>
          <Typography
            sx={{
              fontSize: TEXT_FONT_SIZE,
              fontWeight: 700,
              textOverflow: 'ellipsis',
              color: 'white',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: 'max-content',
            }}
            {...typogrphyProps}
          >
            {label}
          </Typography>
        </Tooltip>
      </Box>
    </foreignObject>
  );
};

export default CSDForignSvgLabel;
