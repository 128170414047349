import React from 'react';
import { IconButton, IconButtonProps, Icon, IconProps } from '@mui/material';

interface Props extends IconButtonProps {
  icon: string;
  isShow?: boolean;
  iconProps?: IconProps;
}

const CSDIconButton = ({ icon, isShow = true, iconProps, ...iconButtonProps }: Props) => {
  return (
    isShow && (
      <IconButton {...iconButtonProps}>
        <Icon {...iconProps}>{icon}</Icon>
      </IconButton>
    )
  );
};

export default CSDIconButton;
