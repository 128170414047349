import React, { FocusEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { Form, useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { Icon, Theme, Typography } from '@mui/material';
import icons from '@app/assets/iconFont';
import { DateComponentType } from '@app/components/datepicker/Datepicker';
import NoteFiles from '@app/components/common/files/NoteFiles';
import RoadAddress, { Values } from '@app/modules/map-module/components/map/notes/form/RoadAddress';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import { CSDDataPickerAntd, CSDInput, CSDInputStandard, CSDSelect } from '@app/modules/kit-module/shared/ui';
import PRIVACY from '@app/core/constants/visibility';
import { sliceLatitudeAndLongitude } from '@app/v2/shared/helpers';
import { MESSAGE_TEXT_LIMIT, TITLE_TEXT_LIMIT, DATE_FORMAT } from '@app/v2/shared/constants';
import { NOTE_IMAGE_UPLOAD_URL } from '@app/core/constants';

const MapNoteForm = () => {
  const { setFieldValue, values, getFieldMeta, errors, handleChange, touched, handleBlur } = useFormikContext<MapNoteInput & { roadAddress?: any }>();
  const { t } = useTranslation('map', { keyPrefix: 'notes.form' });

  const [visibility, setVisibility] = useState<string>(values.visibility);
  const [validValues, setValidValues] = useState<Values>({
    roadId: values.roadId,
    latitude: values.place?.latitude,
    longitude: values.place?.longitude,
    kilometer: values.place?.kilometer,
    meter: values.place?.meter,
  });

  const classes = useStyles();

  const debounceSetValidValues = useRef(
    debounce((condition, v) => {
      if (condition) {
        setValidValues(v);
      }
    }, 800),
  );

  useEffect(() => {
    const longitude = getFieldMeta<number>('place.longitude');
    const latitude = getFieldMeta<number>('place.latitude');
    const roadId = getFieldMeta<number>('roadId');
    const kilometer = getFieldMeta<number>('place.kilometer');
    const meter = getFieldMeta<number>('place.meter');

    debounceSetValidValues.current(!longitude.error && !latitude.error, {
      roadId: roadId.value,
      latitude: latitude.value,
      longitude: longitude.value,
      kilometer: kilometer.value,
      meter: meter.value,
    });
  }, [errors, getFieldMeta]);

  const {
    place: { latitude, longitude },
  } = values;

  const minDate: moment.Moment = useMemo(() => moment().subtract(1, 'days'), []);
  const maxDate: moment.Moment = useMemo(() => moment().endOf('day'), []);

  const handleDateChange = (date: moment.Moment) => {
    setFieldValue('dateTime', moment(date).format(DATE_FORMAT.WITH_TIME_ZONE));
  };

  const handleOnBlur = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>, key: keyof MapNoteInput) => {
    handleBlur(event);
  };

  return (
    <Form>
      <Grid container spacing={2} columns={8} p={2} alignItems="top">
        <Grid item xs={5.3}>
          <CSDInputStandard
            name="title"
            placeholder={t('titlePlaceholder')}
            label={t('title')}
            value={values.title}
            onChange={handleChange}
            error={touched.title && Boolean(errors.title)}
            helperText={touched.title ? errors.title : ''}
            onBlur={event => {
              handleOnBlur(event, 'title');
            }}
            maxLength={TITLE_TEXT_LIMIT}
          />
        </Grid>

        <Grid item xs={2.7} alignItems="flex-end" display="flex">
          <CSDDataPickerAntd
            type={DateComponentType.datetime}
            format={DATE_FORMAT.FORMAT_RU_POINT_FULL_DATE_AND_UNFULL_TIME}
            value={moment(values.dateTime)}
            minDate={minDate}
            maxDate={maxDate}
            ampm={false}
            onChange={handleDateChange}
            showTime
            status={moment(values.dateTime).isBefore(minDate) || moment(values.dateTime).isAfter(maxDate) ? 'error' : undefined}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography variant="body1" className={classes.coordinatesFont}>
            <Icon className={classes.coordinatesIcon}>{icons.pin}</Icon>
            {values.address ? values.address : `${sliceLatitudeAndLongitude(latitude)}, ${sliceLatitudeAndLongitude(longitude)}`}
          </Typography>
        </Grid>

        <RoadAddress values={validValues} />

        <Grid item xs={8}>
          <CSDInput
            name="message"
            label={t('messageLabel')}
            placeholder={t('message')}
            multiline
            rows={6}
            value={values.message}
            onChange={handleChange}
            error={touched.message && Boolean(errors.message)}
            helperText={touched.message ? errors.message : ''}
            onBlur={event => {
              handleOnBlur(event, 'message');
            }}
            maxLength={MESSAGE_TEXT_LIMIT}
          />
        </Grid>

        <Grid item xs={8}>
          <CSDSelect
            label={t('noteVisibility')}
            id="visibility"
            name="visibility"
            value={visibility}
            onChange={e => {
              setVisibility(e.target.value as string);
              setFieldValue('visibility', e.target.value);
            }}
            className={classes.designSelect}
            items={PRIVACY.map(item => ({ ...item, label: t(`visibilitySelect.${item.label}`) }))}
            error={touched.visibility && Boolean(errors.visibility)}
          />
        </Grid>
        <NoteFiles uploadPath={NOTE_IMAGE_UPLOAD_URL} photoField={values.photos} />
      </Grid>
    </Form>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  coordinatesFont: {
    '&&': {
      color: theme.palette.info.main,
      lineHeight: '1.5rem',
    },
  },
  coordinatesIcon: {
    '&&': {
      verticalAlign: 'middle',
      color: theme.palette.primary.main,
      marginRight: '0.1rem',
    },
  },
  designSelect: {
    width: '100%',
    color: theme.palette.primary.main,
  },
}));

export default MapNoteForm;
