import React, { memo, useMemo } from 'react';
import { Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isString } from '@app/v2/shared/helpers';
import { DATE_FORMAT, BASE_COLUMN_WIDTH } from '@app/v2/shared/constants';
import { useShowTime } from '@app/v2/shared/hooks';
import { Pages } from '@app/v2/shared/enums';
import CSDTableCell, { HighlightedCell } from '../CSDTableCells/CSDTableCell';

const StyledStack = styled(Stack)<StackProps>({
  flexDirection: 'column',
  gap: '0.2rem',
  alignItems: 'center',
  justifyContent: 'center',
});

type Props = {
  date: string;
  dateFormat?: string;
  typeOfListing?: Pages;
} & HighlightedCell;

const CSDDateAndTimeCell = ({
  date,
  dateFormat = DATE_FORMAT.FORMAT_RU_POINT_FULL_DATE_AND_UNFULL_TIME,
  rowIndex,
  columnIndex,
  typeOfListing,
}: Props) => {
  const { showTime } = useShowTime();

  const content = useMemo(() => {
    if (!date || !isString(date)) return [];

    return showTime({
      value: date,
      options: {
        format: dateFormat,
        listingType: typeOfListing,
      },
    }).split(' ');
  }, [date, dateFormat, showTime, typeOfListing]);

  return (
    <CSDTableCell align="center" sx={{ width: BASE_COLUMN_WIDTH }} rowIndex={rowIndex} columnIndex={columnIndex}>
      <StyledStack>
        {content.map(str => (
          <Typography sx={{ color: 'inherit' }} fontSize={12} key={str} textAlign="center">
            {str}
          </Typography>
        ))}
      </StyledStack>
    </CSDTableCell>
  );
};

export default memo(CSDDateAndTimeCell);
