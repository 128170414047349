import Yup from '@app/core/validation/locale';
import { i18n } from '@app/v2/shared/localization';

const validationSchema = Yup.object().shape<Auth.AccountSettings>({
  userName: Yup.string().min(3).max(100).required(),
  userEmail: Yup.string().email().required(),
  userPhone: Yup.string()
    .transform(value => String(value.replace(/[^0-9]/g, '').length))
    .oneOf(['1', '11'], i18n.t('validation:wrongPhoneNumber')),
});

export default validationSchema;
