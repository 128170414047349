import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createTransport } from '@sentry/core';
import axios from 'axios';
import { TransportRequest } from '@sentry/types';
import { LOGIN_SUCCESS } from '@store/constants';
import { SentryError } from '@app/core/logger/interfaces/Errors';

function makeFetchTransport(options) {
  async function makeRequest(request: TransportRequest) {
    return axios
      .post(options.url, request.body, {
        url: options.url,
        referrerPolicy: 'origin',
        headers: options.headers,
        ...options.fetchOptions,
      })
      .then(response => {
        return {
          statusCode: response.status,
          headers: {
            'x-sentry-rate-limits': response.headers.get('X-Sentry-Rate-Limits'),
            'retry-after': response.headers.get('Retry-After'),
          },
        };
      });
  }

  return createTransport(options, makeRequest);
}

const initOptions = {
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],

  // https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
  normalizeDepth: 9,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // custom sentry transport
  transport: makeFetchTransport,

  beforeSend(event) {
    const breadcrumbs = event.breadcrumbs?.filter(({ category, data }) => !(category === 'redux.action' && data?.type === LOGIN_SUCCESS));
    const state = event.contexts['redux.state'];

    return {
      ...event,
      breadcrumbs,
      contexts: {
        ...event.contexts,
        'redux.state': state,
      },
    };
  },
};

export class SentryProvider {
  protected options;

  protected sentry = Sentry;

  constructor(options) {
    this.options = options;
  }

  init() {
    this.sentry.init(this.options);
  }

  registerError<Extra extends Record<string, any>>({ message, extra, level }: SentryError<Extra>) {
    this.sentry.captureException(new Error(message), {
      level,
      extra,
    });
  }

  createReduxEnhancer(enhancerOptions = {}): any {
    return this.sentry.createReduxEnhancer(enhancerOptions);
  }
}

const provider = new SentryProvider(initOptions);

export default provider;
