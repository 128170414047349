import React from 'react';
import { Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DATE_FORMAT } from '@app/v2/shared/constants';
import { getScrollStyles } from '@theme/themeSettings/componentSettings';
import { strictlyEqual } from '@app/v2/shared/helpers';
import icons from '@app/assets/iconFont';
import CSDDataPickerRootAntd from './root/CSDDataPickerRootAntd';
import CSDIcons, { CSDIconComponent } from './IconComponents';
import type { SimpleProps, StylesProps } from './types';

const iconsMapperByPickerType: Partial<Record<SimpleProps['picker'], string>> = {
  time: icons.time,
};

const CSDDataPickerAntd = ({ label, format, showTime, size, labelVariant = 'h3', picker, ...other }: SimpleProps) => {
  const classes = useStyles({ showTime, size });

  return (
    <Stack className={classes.wrapper}>
      {!!label && <Typography variant={labelVariant}>{label}</Typography>}

      <CSDDataPickerRootAntd
        size={size}
        picker={picker}
        showTime={showTime}
        className={classes.root}
        popupClassName={classes.dropdown}
        format={format || DATE_FORMAT.FORMAT_RU_POINT_SHORTER_DATE_AND_UNFULL_TIME}
        {...CSDIcons}
        suffixIcon={<CSDIconComponent icon={iconsMapperByPickerType[picker] ?? icons.calender} />}
        {...other}
      />
    </Stack>
  );
};

const useStyles = makeStyles<Theme, StylesProps>(({ palette: { primary, common, text, mode }, typography: { fontWeightBold } }) => ({
  wrapper: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: '0.5rem',
  },
  root: {
    outline: `1px solid ${primary.main}`,
    padding: '0 0.5rem',
    backgroundColor: 'transparent',
    borderRadius: '0.5rem',
    height: ({ size }) => (strictlyEqual<string>(size, 'large') ? '2.5rem' : '1.75rem'),

    '&:hover': {
      outline: `1px solid ${primary.main}`,
    },

    '&.ant-picker-focused .ant-picker-input': {
      '& input': {
        backgroundColor: primary.main,
        color: common.white,
      },

      '& input::placeholder': {
        color: common.white,
      },

      '& .ant-picker-clear': {
        zIndex: 3,

        '& span': {
          color: common.white,
        },
      },
    },

    '& .ant-picker-input': {
      gap: '0.25rem',
      height: '100%',

      '& > input': {
        paddingLeft: '0.5rem',
        height: 'inherit',
      },
    },

    '& .ant-picker-suffix': {
      order: -1,
      margin: 0,
    },

    '& .ant-picker-clear': {
      backgroundColor: 'transparent',
      zIndex: 3,
    },
  },
  dropdown: {
    '& .ant-picker-panel-layout': {
      borderRadius: '0.5rem',
      border: `1px solid ${common.black}`,
      boxShadow: '0px 5px 5px 0px rgba(52, 52, 52, 0.20)',

      '& .ant-picker-panel': {
        padding: '1rem',

        '& .ant-picker-cell.ant-picker-cell-in-view:hover': {
          '& .ant-picker-cell-inner': {
            backgroundColor: 'rgba(50, 69, 175, 0.30) !important',
          },
        },

        '& .ant-picker-date-panel': {
          '& .ant-picker-header': {
            border: 'none',
            padding: 0,

            '& .ant-picker-header-view': {
              fontSize: '1.125rem',
              fontWeight: fontWeightBold,
            },
          },
          '& .ant-picker-body': {
            padding: 0,

            '& .ant-picker-content': {
              width: '100%',

              '& thead tr > *': {
                fontWeight: fontWeightBold,
              },

              '& tbody': {
                '& .ant-picker-cell-selected': {
                  '& .ant-picker-cell-inner': {
                    backgroundColor: primary.main,
                    color: text.t5,
                  },
                },
                '& .ant-picker-cell-today': {
                  '& .ant-picker-cell-inner': {
                    color: primary.main,
                    fontWeight: fontWeightBold,

                    '&::before': {
                      border: 'none',
                    },
                  },
                },
              },
            },
          },
        },

        '& .ant-picker-time-panel': {
          '& .ant-picker-content': {
            '& .ant-picker-time-panel-column': {
              ...getScrollStyles(mode, text),
            },
          },
        },

        '& .ant-picker-footer': {
          display: ({ showTime }) => !showTime && 'none',
          border: 'none',

          '& .ant-picker-ranges': {
            '& .ant-picker-now a': {
              color: primary.main,
            },

            '& .ant-picker-ok': {
              '& > button': {
                color: common.white,
                backgroundColor: primary.main,
              },

              '& > button:disabled': {
                color: text.t3,
                backgroundColor: text.t5,
              },
            },
          },
        },
      },
    },
  },
}));

export default CSDDataPickerAntd;
