// eslint-disable-next-line import/prefer-default-export
export enum UsersAccess {
  PAGE_REGISTER = 'page.register',
  PAGE_HOME = 'page.home',
  PAGE_METEO = 'page.meteo',
  PAGE_VIDEO = 'page.video',
  PAGE_TRAFFIC = 'page.traffic',
  PAGE_MAP = 'page.map',
  VMS_VIEW_ONLY = 'vms.viewOnly',
  VMS_LIMIT_EDIT = 'vms.limitEdit',
  VMS_FULL_EDIT = 'vms.fullEdit',
  PAGE_JOURNALS = 'page.journals',
  PAGE_REFERENCES = 'page.references',
  PAGE_SERVICES = 'page.services',
  PAGE_SAPO = 'page.sapo',
  PAGE_SOPS = 'page.sops',
  PAGE_SCLR = 'page.sclr',
  PAGE_DPGT = 'page.dpgt',
  PAGE_NOTES = 'page.notes',
  PAGE_LOCATOR_ANALYSIS = 'page.locator.analyze',
  PAGE_ECO_MONITORING = 'page.meteo.eco',
  PAGE_DPD = 'page.meteo.dpd',
  PAGE_METEO_POPUP = 'page.meteo.popup',
  PAGE_VIDEO_POPUP = 'page.video.popup',
  PAGE_TRAFFIC_POPUP = 'page.traffic.popup',
  PAGE_SCLR_POPUP = 'page.sclr.popup',
  PAGE_NEWS = 'page.news',
  PAGE_DASHBOARD = 'page.dashboard',
  BUTTON_VIDEO_REPORT = 'button.video.report',
  BUTTON_VIDEO_CLEAR = 'button.video.clear',
  BUTTON_VIDEO_LIVE = 'button.video.live',
  BUTTON_VIDEO_POPUP_DATAPICKER = 'button.video.popup.datapicker',
  BUTTON_METEO_REPORT = 'button.meteo.report',
  BUTTON_METEO_LINEAR_PLAN_GRAPHS = 'button.meteo.linear_plan_graphs',
  BUTTON_ASINOVSKIY_REPORT = 'report.Asinovskiy',
  METEO_ROADCAST_AND_FORECAST = 'meteo.roadcast_and_forecast',
  BUTTON_TRAFFIC_REPORTS = 'button.traffic.reports',
  BUTTON_JOURNALS_EDIT = 'button.journals.edit',
  EDIT_REFERENCES = 'edit.references',
  MAP_LOCATORS = 'map.locators',
  MAP_LOCATORS_ALL_LAYERS = 'map.locators.all_layers',
  MAP_ROAD_OBJECTS = 'map.road_objects',
  SPECGIDROMET = 'specgidromet',
  SERVICE_ENGINEER = 'serviceEngineer',
  SUPERVISOR = 'supervisor',
  ROOT = 'root',
  VIDEO_ACCIDENTS = 'page.video.accidents',
}
