import React, { ReactNode } from 'react';
import { Popper, Theme, PopperPlacementType } from '@mui/material';
import { makeStyles } from '@mui/styles';

type Props = {
  anchorEl: HTMLDivElement;
  component: ReactNode;
  placement?: PopperPlacementType;
};

const CSDMapMarkerPopover = ({ anchorEl, component, placement = 'bottom' }: Props) => {
  const classes = useStyles();
  const open = Boolean(anchorEl);

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      className={classes.popper}
      disablePortal
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, -16],
          },
        },
        {
          name: 'flip',
          enabled: false,
        },
        {
          name: 'preventOverflow',
          enabled: false,
        },
      ]}
      popperOptions={{
        strategy: 'fixed',
      }}
    >
      {component}
    </Popper>
  );
};

const useStyles = makeStyles<Theme>(() => ({
  popper: {
    top: '-1rem',
    '& .MuiPaper-root': {
      borderRadius: 0,
      boxShadow: 'unset',
    },
  },
}));

export default CSDMapMarkerPopover;
