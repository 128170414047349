import * as React from 'react';
import clsx from 'clsx';
import { TableCell, Box, BoxProps } from '@mui/material';
import { makeStyles, ClassNameMap } from '@mui/styles';
import { Theme } from '@mui/material/styles';

enum HeaderTypes {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

interface HeaderSettings extends BoxProps {
  show?: boolean;
  text?: string;
  Icon?: React.ReactNode;
}

export interface CSDGroupCellProps extends BoxProps {
  colSpan?: number;
  topHeaderSettings?: HeaderSettings;
  bottomHeaderSettings?: HeaderSettings;
  isLastGroupCellOnTable?: boolean;
}

const getHeadElement = ({ show = false, text, Icon, className, ...otherProps }: HeaderSettings, classes: ClassNameMap, variant = HeaderTypes.TOP) => {
  if (!show) {
    return null;
  }
  return (
    <Box
      className={clsx(classes.groupRowElement, className, {
        [classes.variantToTop]: variant === HeaderTypes.TOP,
        [classes.variantToBottom]: variant === HeaderTypes.BOTTOM,
      })}
      {...otherProps}
    >
      {Icon && Icon}
      {text && text}
    </Box>
  );
};

const CSDGroupCell = ({
  topHeaderSettings = {},
  bottomHeaderSettings = {},
  colSpan = 1,
  className,
  isLastGroupCellOnTable,
  ...otherProps
}: CSDGroupCellProps) => {
  const classes = useStyles();

  return (
    <TableCell colSpan={colSpan} className="groupHeaderCell">
      <Box
        className={clsx(classes.groupCellContainer, className, {
          [classes.lastCellOnTable]: isLastGroupCellOnTable,
        })}
        {...otherProps}
      >
        {getHeadElement(topHeaderSettings, classes, HeaderTypes.TOP)}
        {getHeadElement(bottomHeaderSettings, classes, HeaderTypes.BOTTOM)}
      </Box>
    </TableCell>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  groupCellContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  groupRowElement: {
    height: '1.75rem',
    display: 'flex',
    alignItems: 'center',
    borderStyle: 'solid',
    color: theme.palette.text.t2,
    borderColor: theme.palette.text.t2,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1rem',
    padding: '0.375rem 1rem',
    marginLeft: '1.25rem',
  },
  variantToTop: {
    alignSelf: 'end',
    borderRadius: '0.5rem 0.5rem 0px 0px',
    borderWidth: '1px 1px 0px 1px',
  },
  variantToBottom: {
    alignSelf: 'start',
    borderRadius: '0px 0px 0.5rem 0.5rem',
    borderWidth: '0px 1px 1px 1px',
  },
  lastCellOnTable: {
    marginLeft: '14rem',
  },
}));

export default CSDGroupCell;
