import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Icon, IconButton, Paper, PaperProps, Popover, Stack, StackProps, Tooltip, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import icons from '@app/assets/iconFont';
import { CSDAttentionSvgIcon, CSDIconPanelPaper, CSDLinkIconButton } from '@app/modules/kit-module/shared/ui';
import AccountSettingsDialog from '@app/components/dialogs/account-settings-dialog/AccountSettingsDialog';
import { NOTIFICATION_LIMIT } from '@app/core/constants/notification';
import { useAppSelector, useCurrentPage, useDialog, usePopover, useSystem } from '@app/v2/shared/hooks';
import { CSDSidebar } from '@app/v2/entities';
import { DataTestIds, MainPaths } from '@app/v2/shared/enums';
import { ENTER_TOOLTIP_DELAY, USER_LABEL_LENGTH_WITHOUT_TOOLTIP } from '@app/v2/shared/constants';
import useAccountUnpaidStatus from '@app/v2/shared/hooks/useAccountUnpaidStatus';
import useSearchQuery from '@app/core/hooks/useSearchQuery';
import { COLORS } from '@theme/constants';
import { useWSContext } from '@app/v2/shared/contexts';
import { anchorOrigin, transformOrigin } from '../configurations/menu';

const CSDHeaderUserPanel = () => {
  const classes = useStyles();

  const { t } = useTranslation('header', { keyPrefix: 'userPanel' });
  const { anchorEl, isOpen, openPopover, closePopover } = usePopover();

  const isUnpaid = useAccountUnpaidStatus();
  const search = useSearchQuery();

  const { isNotificationOpen, handleChangeNotificationStatus } = useSystem();
  const currentPage = useCurrentPage();

  const {
    data: { bulletin, alert },
  } = useWSContext();
  const openSettingsDialog = useDialog(AccountSettingsDialog);

  const userName = useAppSelector(state => state.account.settings.userName);

  const notificationsCount = useMemo(() => [...bulletin, ...alert.filter(item => item.meta.isActive)].length, [bulletin, alert]);

  return (
    <CSDIconPanelPaper>
      <Badge
        color="secondary"
        max={NOTIFICATION_LIMIT}
        badgeContent={notificationsCount}
        onClick={() => handleChangeNotificationStatus(!isNotificationOpen)}
      >
        <Tooltip title={t('notifications')}>
          <IconButton>
            <Icon>{icons.bell}</Icon>
          </IconButton>
        </Tooltip>
      </Badge>

      <Tooltip title={t('favourites')}>
        <CSDLinkIconButton
          iconColor={currentPage?.path === MainPaths.Favorites ? COLORS.primaryMain : COLORS.black}
          icon={currentPage?.path === MainPaths.Favorites ? icons.starFill : icons.starOutline}
          linkProps={{
            to: { pathname: MainPaths.Favorites, search },
          }}
        />
      </Tooltip>

      <StyledStack onClick={openSettingsDialog}>
        <Tooltip title={t('account')}>
          {isUnpaid ? (
            <div className={classes.iconViewport}>
              <div className={classes.iconContainer}>
                <Icon>{icons.person}</Icon>
                <CSDAttentionSvgIcon isAnimated />
              </div>
            </div>
          ) : (
            <Icon>{icons.person}</Icon>
          )}
        </Tooltip>

        <Tooltip title={userName} arrow enterDelay={ENTER_TOOLTIP_DELAY} disableHoverListener={userName?.length < USER_LABEL_LENGTH_WITHOUT_TOOLTIP}>
          <StyledTypography>{userName}</StyledTypography>
        </Tooltip>
      </StyledStack>

      <Tooltip title={t('menu')}>
        <IconButton onClick={openPopover} data-testid={DataTestIds.OpenSidebarBtn}>
          <Icon>{icons.burger}</Icon>
        </IconButton>
      </Tooltip>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        marginThreshold={0}
        style={{ maxHeight: 'calc(100vh - 10%)' }}
      >
        <StyledPaper>
          <CSDSidebar onClose={closePopover} />
        </StyledPaper>
      </Popover>
    </CSDIconPanelPaper>
  );
};

const StyledStack = styled(Stack)<StackProps>({
  cursor: 'pointer',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '0.5rem',
});

const StyledTypography = styled(Typography)<TypographyProps>({
  maxWidth: '8rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  padding: '0.5rem',
  backgroundColor: theme.palette.background.third,
  maxWidth: '20rem',
}));

const useStyles = makeStyles(() => ({
  '@keyframes iconContainertAnimation': {
    '0%': {
      transform: 'translateY(0%)',
    },
    '15%': {
      transform: 'translateY(0%)',
    },
    '20%': {
      transform: 'translateY(-50%)',
    },
    '80%': {
      transform: 'translateY(-50%)',
    },
    '85%': {
      transform: 'translateY(0%)',
    },
    '100%': {
      transform: 'translateY(0%)',
    },
  },
  iconViewport: {
    width: '24px',
    height: '24px',
    overflow: 'hidden',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    animation: '$iconContainertAnimation 5s linear infinite',
  },
}));

export default CSDHeaderUserPanel;
