import React, { MouseEvent, useCallback } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { CircularProgress, Icon, IconButton, Tooltip } from '@mui/material';
import { DocumentNode } from 'graphql/language/ast';
import icons from '@app/assets/iconFont';
import useDialog from '@app/core/hooks/useDialog';
import ConfirmDialog from '@app/components/dialogs/confirm-dialog/ConfirmDialog';

type Props = {
  id: number;
  query: DocumentNode;
  onDelete?: () => void;
} & WithTranslation<'common'>;

const DeleteButton = ({ id, query, onDelete, t }: Props) => {
  const [deleteItem, { loading }] = useMutation(query);
  const openConfirmDialog = useDialog(ConfirmDialog);

  const deleteHandler = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      openConfirmDialog({ question: t('confirmationQuestion') }).beforeClose.subscribe(async (answerToQuestion: boolean): Promise<void> => {
        if (answerToQuestion) {
          deleteItem({ variables: { id } }).finally(() => {
            typeof onDelete === 'function' && onDelete();
          });
        }
      });

      return false;
    },
    [openConfirmDialog, t, deleteItem, id, onDelete],
  );

  return (
    <Tooltip title={t('buttons.delete')}>
      <IconButton data-testid="delete" onClick={deleteHandler}>
        {loading ? (
          <CircularProgress data-testid="loading" color="inherit" />
        ) : (
          <Icon color="action" sx={{ '&:hover': { color: '#3245af' } }}>
            {icons.trash}
          </Icon>
        )}
      </IconButton>
    </Tooltip>
  );
};

export default withTranslation('common')(DeleteButton);
