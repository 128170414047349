import { EMPTY_STRING } from '@app/v2/shared/constants';

function createTableRowClassList(row, selected = false): string {
  const list: { [key: string]: string } = {
    alertStatus: row?.alertStatus ? String(row?.alertStatus) : EMPTY_STRING,
    unpaidStatus: row?.isUnpaid ? 'unpaid' : EMPTY_STRING,
    selected: selected ? 'selected' : EMPTY_STRING,
  };

  return Object.entries(list)
    .map(([_, value]) => value)
    .join(' ');
}

export default createTableRowClassList;
