import * as Yup from 'yup';
import moment from 'moment';
import { dateValidatorNotes, kilometers, meters } from '@app/modules/log-module/common/validation/validators';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import { i18n } from '@app/v2/shared/localization';
import PRIVACY from '@app/core/constants/visibility';

export default Yup.object().shape<MapNoteInput & { roadAddress?: any }>({
  title: Yup.string().nullable().required(),
  id: Yup.number().nullable(),
  dateTime: dateValidatorNotes.test(
    'date',
    i18n.t('validation:dateAndTime'),
    value => moment(value).unix() >= moment().add(-1, 'days').unix() && moment(value).unix() <= moment().endOf('day').unix(),
  ),
  roadId: Yup.number().nullable().required(),
  message: Yup.string().nullable().required(),
  photos: Yup.array().of(Yup.string()).nullable(),
  place: Yup.object().shape({
    id: Yup.number().nullable(),
    latitude: Yup.number().nullable().required(),
    longitude: Yup.number().nullable().required(),
    kilometer: kilometers.optional().nullable(),
    meter: meters.optional().nullable(),
  }),
  visibility: Yup.string()
    .oneOf(PRIVACY.map(item => item.value))
    .required(),
  roadAddress: Yup.string().optional().nullable(),
  address: Yup.string().optional().nullable(),
});
