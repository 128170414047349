import React from 'react';
import { MapLayer, withLeaflet, LeafletContext } from 'react-leaflet';
import { DivIcon, Marker, marker } from 'leaflet';
import Portal from '@mui/material/Portal';
import MapProfileContext from './context/CustomMarkerContext';

class CustomMarker extends MapLayer<any> {
  leafletElement: Marker;

  contextValue: LeafletContext;

  constructor(props) {
    super(props);
    this.leafletElement = this.createLeafletElement(this.props);
    this.leafletElement.on('add', () => this.forceUpdate());
    this.changeZIndexOffset = this.changeZIndexOffset.bind(this);
  }

  createLeafletElement(props: any): Marker {
    const { map, leaflet, position, ...rest } = props;

    const icon = new DivIcon({ ...rest, iconSize: undefined });

    const el = marker(position, { icon, ...rest });
    this.contextValue = { ...leaflet, popupContainer: el };

    return el as Marker;
  }

  changeZIndexOffset(offset: number): Marker {
    return this.leafletElement.setZIndexOffset(offset);
  }

  render() {
    const container = this.leafletElement.getElement();

    return (
      !!container && (
        <MapProfileContext.Provider
          value={{
            latLng: this.props.position,
            options: this.leafletElement.options,
            setZIndexOffset: this.changeZIndexOffset,
          }}
        >
          <Portal container={container}>{this.props.children}</Portal>
        </MapProfileContext.Provider>
      )
    );
  }
}

export default withLeaflet(CustomMarker);
