import { MainPaths } from '../enums/MainPaths';

const pagesWithoutFilters: string[] = [
  MainPaths.Index,
  MainPaths.CityWeatherForecast,
  MainPaths.GuidesRoads,
  MainPaths.GuidesOrganizations,
  MainPaths.GuidesUsers,
  MainPaths.ScoreboardConfiguration,
  MainPaths.ScoreboardSigns,
  MainPaths.AccidentNews,
  MainPaths.FederalRoads,
  MainPaths.FederalRoadsSegments,
];

export default pagesWithoutFilters;
