import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import { Box, Chip, ChipProps, CircularProgress, Icon, InputAdornment, TextField, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AutocompleteRenderGetTagProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete/Autocomplete';
import icons from '@app/assets/iconFont';
import { typedMemo } from '@app/v2/shared/utils';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import CSDRootAutocomplete from './CSDRootAutocomplete';
import type { AutocompleteProps } from './types';

const CSDAutocomplete = <Option extends Common.SelectorValue, Multiple extends boolean = true>({
  id,
  label,
  disabled,
  showFullLabelTooltip,
  inputProps,
  autocompleteProps,
}: AutocompleteProps<Option, Multiple>) => {
  const renderInput = useMemo(
    () =>
      (params: AutocompleteRenderInputParams): JSX.Element => {
        const { InputProps } = params;

        return (
          <TextField
            {...params}
            size="small"
            disabled={disabled}
            InputProps={{
              ...InputProps,
              startAdornment: InputProps?.startAdornment ?? (
                <InputAdornment sx={{ margin: 0 }} position="start">
                  <Icon>{icons.search}</Icon>
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {/* eslint-disable-next-line react/prop-types */}
                  {autocompleteProps?.loading ? <CircularProgress sx={{ marginRight: '0.5rem' }} size={20} /> : null}
                  {InputProps.endAdornment}
                </>
              ),
            }}
            {...inputProps}
          />
        );
      },
    [autocompleteProps?.loading, disabled, inputProps],
  );

  const renderTags = useMemo(
    () =>
      (
        tagValue: Option[],
        getTagProps: AutocompleteRenderGetTagProps,
        { options }: AutocompleteProps<Option, Multiple>['autocompleteProps'],
      ): JSX.Element[] =>
        tagValue.map(
          (option: Option, index: number): JSX.Element => (
            <StyledChip
              key={option?.value}
              variant="outlined"
              deleteIcon={<Icon>{icons.close}</Icon>}
              label={autocompleteProps.loading ? '...' : option?.label ?? options.find(({ value }) => value === option?.value)?.label}
              {...getTagProps({ index })}
            />
          ),
        ),
    [autocompleteProps.loading],
  );

  const defaultRenderOptions = useMemo(
    () =>
      (renderProps: HTMLAttributes<HTMLElement>, { value, label: defaultLabel }: Option) =>
        (
          <Tooltip title={showFullLabelTooltip ? defaultLabel : null}>
            <li {...renderProps} key={value}>
              {defaultLabel}
            </li>
          </Tooltip>
        ),
    [showFullLabelTooltip],
  );

  const getOptionLabelDefault = useCallback(
    (option: Option): string => option?.label ?? autocompleteProps.options.find(({ value }) => value === option?.value)?.label ?? EMPTY_STRING,
    [autocompleteProps.options],
  );
  const isOptionEqualToValueDefault = useCallback((a: Option, b: Option): boolean => a?.value === b?.value, []);

  return (
    <Box sx={{ width: inputProps?.fullWidth && '100%' }}>
      {!!label && (
        <Typography variant="subtitle2" sx={{ marginBottom: '0.5rem', fontSize: '0.875rem' }}>
          {label}
        </Typography>
      )}

      <CSDRootAutocomplete<Option>
        id={id}
        renderInput={renderInput}
        getOptionLabel={getOptionLabelDefault}
        isOptionEqualToValue={isOptionEqualToValueDefault}
        renderOption={defaultRenderOptions}
        renderTags={renderTags}
        disabled={disabled}
        {...autocompleteProps}
      />
    </Box>
  );
};

const StyledChip = styled(Chip)<ChipProps>({
  '& .MuiChip-label': {
    padding: 0,
    marginRight: '0.5rem',
  },
  '& .MuiChip-deleteIcon': {
    color: 'inherit',
    margin: 0,
    fontSize: '1rem',
  },
});

export default typedMemo(CSDAutocomplete);
