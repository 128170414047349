import Pages from '../enums/Pages';
import { MainPaths } from '../enums/MainPaths';

const pages: Common.PagesItem[] = [
  { path: MainPaths.Index, name: Pages.Index, label: 'indexHeader' },
  { path: MainPaths.Meteo, name: Pages.Meteo, label: 'meteo' },
  { path: MainPaths.Traffic, name: Pages.Traffic, label: 'traffic' },
  { path: MainPaths.Map, name: Pages.Map, label: 'map' },
  { path: MainPaths.Video, name: Pages.Video, label: 'video' },
  { path: MainPaths.VideoWaterLevelControl, name: Pages.VideoWaterLevelControl, label: 'videoWaterLevelControl' },
  { path: MainPaths.MapNotes, name: Pages.MapNotes, label: 'mapNotes' },
  { path: MainPaths.ScoreboardsAndSigns, name: Pages.ScoreboardsAndSigns, label: 'scoreboardsAndSignsV2' },
  { path: MainPaths.AdaptationToClimateChange, name: Pages.AdaptationToClimateChange, label: 'adaptationToClimateChange' },
  { path: MainPaths.SOPS, name: Pages.SOPS, label: 'SOPS' },
  { path: MainPaths.ScoreboardConfiguration, name: Pages.ScoreboardConfiguration, label: 'configuration' },
  { path: MainPaths.ScoreboardPlaylists, name: Pages.ScoreboardPlaylists, label: 'playlists' },
  { path: MainPaths.ScoreboardTemplates, name: Pages.ScoreboardTemplates, label: 'scoreboard' },
  { path: MainPaths.ScoreboardSigns, name: Pages.ScoreboardSigns, label: 'signs' },
  { path: MainPaths.ScoreboardDevices, name: Pages.ScoreboardDevices, label: 'devices' },
  { path: MainPaths.GuidesUsers, name: Pages.GuidesUsers, label: 'guidesUsers' },
  { path: MainPaths.GuidesRoads, name: Pages.GuidesRoads, label: 'guidesRoads' },
  { path: MainPaths.GuidesOrganizations, name: Pages.GuidesOrganizations, label: 'guidesOrganizations' },
  { path: MainPaths.MeteoManagement, name: Pages.MeteoManagement, label: 'management' },
  { path: MainPaths.TrafficManagement, name: Pages.TrafficManagement, label: 'management' },
  { path: MainPaths.VideoManagement, name: Pages.VideoManagement, label: 'management' },
  { path: MainPaths.CityWeatherForecast, name: Pages.CityWeatherForecast, label: 'cityWeatherForecast' },
  { path: MainPaths.LogAccident, name: Pages.LogAccident, label: 'accident' },
  { path: MainPaths.LogHazard, name: Pages.LogHazard, label: 'dutyForecast' },
  { path: MainPaths.LogDuty, name: Pages.LogDuty, label: 'duty' },
  { path: MainPaths.LogBreaks, name: Pages.LogBreaks, label: 'breaks' },
  { path: MainPaths.LogFloodEffects, name: Pages.LogFloodEffects, label: 'floodEffects' },
  { path: MainPaths.LogForcesAttracting, name: Pages.LogForcesAttracting, label: 'forcesAttracting' },
  { path: MainPaths.LogTrafficRestriction, name: Pages.LogTrafficRestriction, label: 'trafficRestriction' },
  { path: MainPaths.LogEmergencySituation, name: Pages.LogEmergencySituation, label: 'emergencySituation' },
  { path: MainPaths.LogEmergencySituationBuilding, name: Pages.LogEmergencySituationBuilding, label: 'emergencySituationBuilding' },
  { path: MainPaths.LogEmergencySituationFacility, name: Pages.LogEmergencySituationFacility, label: 'emergencySituationFacility' },
  { path: MainPaths.Notifications, name: Pages.Notifications, label: 'notifications' },
  { path: MainPaths.LocatorAnalysis, name: Pages.LocatorAnalyze, label: 'locatorAnalysis' },
  { path: MainPaths.MeteoReports, name: Pages.MeteoReports, label: 'meteoReports' },
  { path: MainPaths.TrafficReports, name: Pages.TrafficReports, label: 'trafficReports' },
  { path: MainPaths.VideoReports, name: Pages.VideoReports, label: 'videoReports' },
  { path: MainPaths.ServiceMeteoReports, name: Pages.ServiceMeteoReports, label: 'serviceMeteoReports' },
  { path: MainPaths.ServiceTrafficReports, name: Pages.ServiceTrafficReports, label: 'serviceTrafficReports' },
  { path: MainPaths.ServiceVideoReports, name: Pages.ServiceVideoReports, label: 'serviceVideoReports' },
  { path: MainPaths.ECOMonitoring, name: Pages.ECOMonitoring, label: 'ecoMonitoring' },
  { path: MainPaths.StationDetails, name: Pages.StationDetails, label: 'stationDetails' },
  { path: MainPaths.Favorites, name: Pages.Favorites, label: 'favorites' },
  { path: MainPaths.Reports, name: Pages.Reports, label: 'reports' },
  { path: MainPaths.ReportsServices, name: Pages.Reports, label: 'reports.services' },
  { path: MainPaths.AccidentNews, name: Pages.AccidentNews, label: 'accidentNews' },
  { path: MainPaths.FederalRoads, name: Pages.FederalRoads, label: 'federalRoads' },
  { path: MainPaths.FederalRoadsSegments, name: Pages.FederalRoadsSegments, label: 'federalRoadsSegments' },
  { path: MainPaths.Incidents, name: Pages.Incidents, label: 'incident' },
  { path: MainPaths.Dashboard, name: Pages.Dashboard, label: 'dashboard' },
];

export default pages;
