import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import { makeStyles } from '@mui/styles';
import icons from '@app/assets/iconFont';
import CSDSliderArrow from './CSDSliderArrow';

type HorizontalSliderProps = {
  slidesToShow?: number;
  slidesToScroll?: number;
  dots?: boolean;
  className?: string;
  children: ReactNode;
  nextArrow?: JSX.Element;
  prevArrow?: JSX.Element;
  nextArrowClassName?: string;
  prevArrowClassName?: string;
};

const CSDHorizontalSlider = ({
  slidesToShow = 1,
  slidesToScroll = 1,
  dots = true,
  children,
  nextArrow,
  prevArrow,
  nextArrowClassName,
  prevArrowClassName,
  ...otherSliderSettings
}: HorizontalSliderProps) => {
  const classes = useStyles();

  const settings = {
    dots,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow,
    slidesToScroll,
    nextArrow: nextArrow || <CSDSliderArrow additionalClassName={nextArrowClassName || classes.rigthSizeButton} />,
    prevArrow: prevArrow || <CSDSliderArrow additionalClassName={prevArrowClassName || classes.leftSizeButton} icon={icons.left} />,
    appendDots: dotElements => {
      return (
        <div style={{ bottom: '0.25rem' }}>
          <ul className={classes.listDots}>{dotElements}</ul>
        </div>
      );
    },
    ...otherSliderSettings,
  };

  return <Slider {...settings}>{children}</Slider>;
};

const useStyles = makeStyles(() => ({
  leftSizeButton: {
    '&.MuiIconButton-root': {
      background: '#fff',
      borderRadius: '0 0.25rem 0.25rem 0',
    },
    left: 0,
  },
  rigthSizeButton: {
    '&.MuiIconButton-root': {
      background: '#fff',
      borderRadius: '0.25rem 0 0 0.25rem',
    },
    right: 0,
  },
  listDots: {
    margin: 0,
    '& li.slick-active button': {
      background: '#fff',
    },
    '& li button': {
      width: '0.5rem',
      height: '0.5rem',
      borderRadius: '50%',
      border: '1px solid #fff',
      padding: 0,
      '&:hover': {
        background: '#fff',
      },
      '&::before': {
        display: 'none',
      },
    },
  },
}));

export default CSDHorizontalSlider;
