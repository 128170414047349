import FETCH_PLACE_ACTIVE_ALERTS from './fetchPlaceActiveAlerts.graphql';
import FETCH_START_PAGES from './fetchstartPages.graphql';
import FETCH_ORGS from './organizations.graphql';
import FETCH_ROADS from './roads.graphql';
import SNAPSHOT from './snapshot.graphql';
import MAIN_PAGE from './mainPage.graphql';

const otherQueries = {
  placeActiveAlerts: FETCH_PLACE_ACTIVE_ALERTS,
  startPages: FETCH_START_PAGES,
  organizations: FETCH_ORGS,
  roads: FETCH_ROADS,
  snapshot: SNAPSHOT,
  mainPage: MAIN_PAGE,
};

export default otherQueries;
