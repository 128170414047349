import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useDialog from '@app/core/hooks/useDialog';
import Dialog from '@app/components/notes/note-images-viewer/Dialog';
import { DISPLAYED_IMAGES_SLICE_END, DISPLAYED_IMAGES_SLICE_START, FULL_WIDTH } from '@app/v2/shared/constants';

type Props = {
  images: string[];
  sliced?: boolean;
};

const ImagesViewer = ({ images, sliced }: Props) => {
  const dialog = useDialog<{ images: string[]; startIndex: number }>(Dialog);
  const { t } = useTranslation('map', { keyPrefix: 'notes' });

  const classes = useStyles();

  const handleOpenDialog = (index: number) => {
    dialog({ images, startIndex: index });
  };

  if (images.length === 0) {
    return <>{t('no')}</>;
  }

  const displayedImages = sliced ? images.slice(DISPLAYED_IMAGES_SLICE_START, DISPLAYED_IMAGES_SLICE_END) : images;

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" width={FULL_WIDTH}>
      {displayedImages.map((image, index) => (
        <IconButton key={image} onClick={() => handleOpenDialog(index)} className={classes.buttonPadding}>
          <img src={image} alt="" className={classes.imagesStyle} />
        </IconButton>
      ))}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  buttonPadding: {
    '&&': {
      paddingRight: 'clamp(0.3rem, 25%, 1rem)',
    },
  },
  imagesStyle: {
    width: '2.875rem',
    height: '2.875rem',
    objectFit: 'cover',
    borderRadius: '0.625rem',
    border: '0.1rem solid',
  },
}));

export default ImagesViewer;
