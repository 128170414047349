import React from 'react';
import { createEmptyArray } from '@app/v2/shared/utils';
import CSDDynamicRoadItem from './CSDDynamicRoadItem';
import CSDForignSvgLabel from '../CSDForignSvgLabel';
import RoadDirectionType from '../../enums/RoadDirectionType';
import { TEXT_FONT_SIZE } from '../../constants';

type Props = {
  forwardRoadTitle?: string;
  backwardRoadTitle?: string;
  roadHeight: number;
  forward: number;
  backward: number;
  hasMiddleStation?: boolean;
};

const CSDDynamicRoad = ({ forward, backward, roadHeight, forwardRoadTitle, backwardRoadTitle, hasMiddleStation = false }: Props) => {
  const forwardBackwardArray = [
    ...createEmptyArray(backward).map((_, index) => ({ type: RoadDirectionType.backward, key: `road-${RoadDirectionType.backward}-${index}` })),
    ...createEmptyArray(forward).map((_, index) => ({ type: RoadDirectionType.forward, key: `road-${RoadDirectionType.forward}-${index}` })),
  ];

  const indexOfOppositeDirection = forwardBackwardArray.findIndex(({ type }) => type === RoadDirectionType.forward);

  const hasMiddleDoubleLine = backward >= 2 || forward >= 2 || hasMiddleStation;

  return (
    <g style={{ transform: `translate(0, ${roadHeight}%)` }}>
      <CSDForignSvgLabel
        x="5%"
        width="40%"
        height={TEXT_FONT_SIZE}
        y={`calc(${roadHeight / 2}% - ${TEXT_FONT_SIZE / 2}px)`}
        label={backwardRoadTitle}
      />
      {forwardBackwardArray.map(({ type, key }, index) => (
        <CSDDynamicRoadItem
          key={key}
          index={index}
          roadHeight={roadHeight}
          directionType={type}
          isOppositeDitectionIndex={index === indexOfOppositeDirection}
          isLast={index === forwardBackwardArray.length - 1}
          showMiddleDoubleLine={hasMiddleDoubleLine}
        />
      ))}
      <CSDForignSvgLabel
        x="55%"
        width="40%"
        height={TEXT_FONT_SIZE}
        y={`calc(${roadHeight * forwardBackwardArray.length - roadHeight / 2}% - ${TEXT_FONT_SIZE / 2}px)`}
        label={forwardRoadTitle}
        typogrphyProps={{ textAlign: 'end' }}
        containerProps={{ display: 'flex', justifyContent: 'end' }}
      />
    </g>
  );
};

export default CSDDynamicRoad;
