import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Dialog, DialogTitle, IconButton, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { useActions } from '@app/v2/shared/hooks';

const NotePositionDialog = ({ close }: DialogRef) => {
  const { t } = useTranslation('dialogs');
  const classes = useStyles();
  const { setMapNotesCursor } = useActions();

  return (
    <Dialog open fullWidth onClose={() => close()}>
      <DialogTitle className={classes.title}>
        <Typography variant="h6">{t('notePosition.title')}</Typography>
        <IconButton onClick={() => close()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{t('notePosition.message')}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actionPanel}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setMapNotesCursor(true);
            close();
          }}
        >
          {t('common.buttons.proceed')}
        </Button>
        <Button variant="outlined" color="secondary" onClick={() => close()}>
          {t('common.buttons.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionPanel: {
    padding: 8,
    height: 36,
    margin: '16px 0px 16px 0px',
  },
}));

export default NotePositionDialog;
