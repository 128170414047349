import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import valueMapper from '../../config/valueMapper';

interface Props {
  devices: {
    stationId: number;
    settings: Scoreboards.DeviceSettings;
  }[];
  activeId: number;
}

const CSDViewDeviceSettingsDialogContent = ({ devices, activeId }: Props) => {
  const classes = useStyles();

  const { t } = useTranslation('scoreboard');

  return (
    <Box className={classes.infoWrapper}>
      {Object.entries(devices.find(({ stationId }) => stationId === activeId).settings).map(([field, value]) => (
        <Box key={field} className="item">
          <Typography variant="subtitle2">{t(`devices.form.${field}` as any)}</Typography>

          {value ? (
            <Typography variant="body1">{valueMapper?.[field] ? valueMapper[field](value) : value}</Typography>
          ) : (
            <Typography variant="body1">{t('devices.info.noData')}</Typography>
          )}
        </Box>
      ))}
    </Box>
  );
};

const useStyles = makeStyles({
  infoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    rowGap: '1.5rem',
    paddingTop: '1rem',
    alignItems: 'center',

    '& .item': {
      flexBasis: '25%',
      maxWidth: '25%',
      minHeight: '4rem',
      display: 'flex',
      flexDirection: 'column',
      rowGap: '0.5rem',
      alignItems: 'center',
    },
  },
});

export default CSDViewDeviceSettingsDialogContent;
