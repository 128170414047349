import React, { memo } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CSDChangePlaylistForDevice,
  CSDDeleteTemplateFromPlaylistBtn,
  CSDDetachPlaylistFromDeviceBtn,
  CSDUpdatePlaylistBtn,
  CSDUpdateTemplate,
} from '@app/v2/features';
import { DataTestIds } from '@app/v2/shared/enums';
import { CSDTPICards } from '@app/v2/entities';

interface Props extends I18N.TranslationFunction<'map', 'tpiDialog'> {
  stationId: number;
  device: Scoreboards.Device;
  fetchStation: Common.VoidCallBack;
}

const CSDMapTPIContent = ({ stationId, device, fetchStation, t }: Props) => {
  const classes = useStyles();

  return (
    <Stack className={classes.wrapper} data-testid={DataTestIds.MapTpiContent}>
      <Stack>
        <Typography variant="h6">{t('playlist')}</Typography>
        <Stack>
          <CSDChangePlaylistForDevice stationId={stationId} success={fetchStation} id={device?.playlist?.id} settings={device.settings} />

          {!!device?.playlist && <CSDDetachPlaylistFromDeviceBtn stationId={stationId} after={fetchStation} />}
        </Stack>
      </Stack>

      {device?.playlist ? (
        <CSDTPICards
          onUpdate={fetchStation}
          playlist={{
            ...device.playlist,
            organizationId: device.playlist.id,
          }}
          type={device.settings.type}
          updatePlaylist={CSDUpdatePlaylistBtn}
          updateTemplateContent={CSDUpdateTemplate}
          deleteTemplateFrom={CSDDeleteTemplateFromPlaylistBtn}
        />
      ) : (
        <Box className={classes.emptyData} data-testid={DataTestIds.MapTpiEmptyContent}>
          <Typography variant="h6">{t('noPlaylist')}</Typography>
        </Box>
      )}
    </Stack>
  );
};

const useStyles = makeStyles({
  wrapper: {
    padding: '0 2rem',

    '&.MuiStack-root': {
      gap: '1.5rem',

      '& > .MuiStack-root': {
        gap: '0.5rem',

        '& > .MuiStack-root': {
          gap: '0.5rem',
          flexDirection: 'row',
        },
      },
    },
  },
  emptyData: {
    width: '100%',
    height: '15rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default withTranslation('map', { keyPrefix: 'tpiDialog' })(memo(CSDMapTPIContent));
