import groupBy from 'lodash/groupBy';

const transformToFlatData = (columns, depth = 0) => {
  return columns.reduce((acc, column) => {
    const col = { ...column, depth };
    acc.push(col);

    if (Object.prototype.hasOwnProperty.call(column, 'children')) {
      return [...acc, ...transformToFlatData(column.children, depth + 1)];
    }

    return acc;
  }, []);
};

export default function useTableHeader(data) {
  const flatHeaderData = transformToFlatData(data);

  const columnsWithoutData = flatHeaderData.filter(column => Object.prototype.hasOwnProperty.call(column, 'children'));

  const columnsWithData = flatHeaderData.reduce((acc, currentColumn) => {
    if (!Object.prototype.hasOwnProperty.call(currentColumn, 'children')) {
      const columnWithIndex = { ...currentColumn, columnIndex: acc.length };
      acc.push(columnWithIndex);
    }

    return acc;
  }, []);

  const headerRows = groupBy([...columnsWithData, ...columnsWithoutData], 'depth');

  return {
    headerRows,
    headerColumns: columnsWithData,
  };
}
