import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Icon, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import moment from 'moment';
import clsx from 'clsx';
import { boxShadowBorder } from '@theme/styles';
import icons from '@app/assets/iconFont';
import { DATE_FORMAT, ENTER_TOOLTIP_DELAY } from '@app/v2/shared/constants';
import { IncidentsTypeSettings, mainConfig } from '@app/v2/shared/configs';
import { getHumanizedAddressWithoutUnit } from '@app/v2/shared/utils';
import { IncidentStatuses } from '@app/v2/shared/enums';

interface Props {
  accident: VideoAccident.Accident;
  actions: ReactNode;
  snapshot?: ReactNode;
}

const colorsByTypesMapper: Record<IncidentStatuses, string> = {
  [IncidentStatuses.Warning]: 'yellow',
  [IncidentStatuses.Alert]: 'red',
};

const CSDIncidentCard = ({ accident, actions, snapshot }: Props) => {
  const classes = useStyles({
    borderColor: colorsByTypesMapper[accident.type],
    imgUrl: accident.snapshotUrl,
  });

  const { t } = useTranslation('incidents');

  return (
    <Box className={clsx(classes.card, 'accidentVideoCard')}>
      <Box className="header">
        <Box>
          <Typography className="address">{getHumanizedAddressWithoutUnit(accident.place.meter, accident.place.kilometer)}</Typography>
          <Typography className="roadTitle">{accident.place.road.title}</Typography>
        </Box>

        <Box>{actions}</Box>
      </Box>

      <Box className="body">
        <Box className="type">
          <Typography className="typeTitle">{t('card.type')}</Typography>
          <Typography className="typeMessage">
            {t(`status.${accident.type}`, {
              object: t(IncidentsTypeSettings[accident.object].translation),
            })}
          </Typography>
        </Box>

        <Box className="object">
          <Typography className="objectTitle">{t('card.object')}</Typography>
          <Icon>{IncidentsTypeSettings[accident.object].icon}</Icon>
        </Box>
      </Box>

      <Box className="footer">
        <Box className="times">
          <Typography className="timesTitle">{t('card.dateAndTime')}</Typography>

          <Box className="date">
            <Box className="item">
              <Icon>{icons.calender}</Icon>
              <Typography>{moment(accident.dateTime).format(DATE_FORMAT.FORMAT_RU)}</Typography>
            </Box>

            <Box className="item">
              <Icon>{icons.time}</Icon>
              <Typography>{moment(accident.dateTime).format(DATE_FORMAT.FORMAT_RU_HOURS)}</Typography>
            </Box>
          </Box>
        </Box>

        <CustomWidthTooltip placement="left-end" title={snapshot} enterDelay={ENTER_TOOLTIP_DELAY} hidden={!snapshot}>
          <Box className="image" />
        </CustomWidthTooltip>
      </Box>
    </Box>
  );
};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '42rem',
    padding: '0.5rem',
  },
});

const useStyles = makeStyles<
  Theme,
  {
    borderColor: string;
    imgUrl: string;
  }
>(({ palette: { text, primary, technicalColors } }) => ({
  card: {
    gap: '0.5rem',
    width: '100%',
    height: '100%',
    padding: '1rem',
    display: 'flex',
    minHeight: '21rem',
    maxHeight: '21rem',
    borderRadius: '0.5rem',
    flexDirection: 'column',
    border: '1px solid black',
    borderTopColor: ({ borderColor }) => technicalColors[borderColor],
    justifyContent: 'space-between',
    boxShadow: ({ borderColor }) => boxShadowBorder(technicalColors[borderColor]),
    transition: 'box-shadow 0.5s, borderColor 0.5s',

    '&:hover': {
      borderColor: ({ borderColor }) => technicalColors[borderColor],
      boxShadow: '0 16px 20px rgb(0 0 0 / 30%)',
    },

    '& .header': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

      '& .address': {
        fontSize: '1.8rem',
        fontWeight: 'bold',
      },

      '& .roadTitle': {
        color: text.t3,
        marginTop: '0.3rem',
      },

      '& .actions': {
        gap: '0.5rem',
        display: 'flex',
        alignItems: 'center',
      },
    },

    '& .body': {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',

      '& .type': {
        gap: '0.5rem',
        display: 'flex',
        flexDirection: 'column',

        '& .typeTitle': {
          color: text.t3,
        },

        '& .typeMessage': {
          maxWidth: 'calc(100% - 5rem)',
          fontSize: '1.5rem',
          fontWeight: 'bold',
        },
      },

      '& .object': {
        width: '6rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',

        '& .objectTitle': {
          color: text.t3,
        },

        '& .custom-icon': {
          '&.MuiIcon-root': {
            fontSize: '4rem',
          },
        },
      },
    },

    '& .footer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& .times': {
        gap: '0.5rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',

        '& .timesTitle': {
          color: text.t3,
        },

        '& .date': {
          gap: '1rem',
          display: 'flex',
          alignItems: 'flex-start',

          '& .item': {
            gap: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',

            '& .custom-icon': {
              '&.MuiIcon-root': {
                color: primary.main,
              },
            },
          },
        },
      },

      '& .image': {
        width: '6rem',
        height: '4rem',
        backgroundSize: 'cover',
        backgroundImage: ({ imgUrl }) => `URL(${mainConfig.links.videoImagesHost + imgUrl.replace('full', 'w150')})`,
      },
    },
  },
}));

export default CSDIncidentCard;
