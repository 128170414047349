import React, { memo } from 'react';
import { AppBar, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import clsx from 'clsx';
import { useAccount, useLayoutConfig, useNewFilters, useSystem } from '@app/v2/shared/hooks';
import { CSDClocks, CSDOpenChatBtn, CSDPaginationSearch } from '@app/modules/kit-module/shared/ui';
import { CLOSE_HEADER_HEIGHT, OPEN_HEADER_HEIGHT } from '@app/core/theme/constants';
import { CSDFastLinks, CSDFavoritesLayouts, CSDInfoBadge, CSDScoreboardLinks } from '@app/v2/entities';
import CSDHeaderCurrentPage from './CSDHeaderCurrentPage';
import CSDHeaderCollapsibleSwitcher from './CSDHeaderCollapsibleSwitcher';
import CSDHeaderVideoWallSwitcher from './CSDHeaderVideoWallSwitcher';
import CSDHeaderUserPanel from './CSDHeaderUserPanel';
import CSDHeaderFilterSection from './CSDHeaderFilterSection/CSDHeaderFilterSection';
import type { HeaderStylesProps } from '../types/index';
import getHeaderInfoWrapperWidth from '../helpers/headerInfoWrapperWidth';

const CSDHeader = () => {
  const {
    headerConfig: {
      isViewFilters,
      isViewInfoBadgeWithForecast,
      isViewVideoWall,
      isViewPagination,
      isViewScoreboardsBlockLinks,
      isViewFavoritesLayouts,
    },
  } = useLayoutConfig();

  const { isHeaderRollUp: isOpen } = useSystem();
  const { settings } = useAccount();
  const { filters } = useNewFilters();

  const classes = useStyles({ isOpen });

  return (
    <AppBar
      className={clsx({
        [classes.openHeader]: isOpen,
        [classes.closedHeader]: !isOpen,
      })}
    >
      <CSDInfoBadge
        clock={!settings.isClockDisabled && <CSDClocks />}
        isViewForecast={isViewInfoBadgeWithForecast}
        isHeaderRollUp={!isOpen}
        fetchVariables={{
          roadIds: filters?.roads,
          organizationIds: filters?.organizations,
          placeIds: filters?.places,
        }}
      />

      <Stack
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '0.5rem 0rem 0rem 1.5rem',
          maxWidth: getHeaderInfoWrapperWidth(isOpen),
        }}
      >
        <Stack direction="row" gap="1.5rem">
          <CSDHeaderCurrentPage />

          {isViewPagination && !isOpen && <CSDPaginationSearch />}
          {isViewScoreboardsBlockLinks && <CSDScoreboardLinks />}
          {isViewFavoritesLayouts && <CSDFavoritesLayouts />}

          <CSDOpenChatBtn />

          {isViewVideoWall && <CSDHeaderVideoWallSwitcher />}
          {isViewFilters && <CSDHeaderCollapsibleSwitcher />}

          <CSDFastLinks />
          <CSDHeaderUserPanel />
        </Stack>

        {isOpen && isViewFilters && <CSDHeaderFilterSection />}
      </Stack>
    </AppBar>
  );
};

const useStyles = makeStyles<Theme, HeaderStylesProps>({
  openHeader: {
    height: OPEN_HEADER_HEIGHT,
    '& + *': {
      marginTop: OPEN_HEADER_HEIGHT,
    },
  },
  closedHeader: {
    height: CLOSE_HEADER_HEIGHT,
    '& + *': {
      marginTop: CLOSE_HEADER_HEIGHT,
    },
  },
});

export default memo(CSDHeader);
