import { PaletteColorsSettings, ThemeMode } from '../types';

const paletteColorsSettings: { [key in ThemeMode]: PaletteColorsSettings } = {
  [ThemeMode.light]: {
    background: {
      default: '#FFFFFF',
      paper: '#D6DAE7',
      first: '#FFFFFF',
      second: '#F3F3F3',
      third: '#DDEFFF',
    },
    text: {
      primary: '#222631',
      secondary: '#8D909A',
      disabled: '#EFEFEF',
      unpaid: '#D3D3D3',
      t1: '#222631',
      t2: '#747474',
      t3: '#8D909A',
      t4: '#B5B8BE',
      t5: '#D6DAE7',
      t6: '#FFFFFF',
      t7: '#F9F9F9',
      t8: '#C1C5D0',
    },
  },
  [ThemeMode.dark]: {
    background: {
      default: '#171717',
      paper: '#222631',
      first: '#171717',
      second: '#363636',
      third: '#616161',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#F2F2F2',
      disabled: '#EFEFEF80',
      t1: '#FFFFFF',
      t2: '#B5B8BE',
      t3: '#F2F2F2',
      t4: '#B5B8BE',
      t5: '#D6DAE7',
      t6: '#FFFFFF',
      t7: '#EFEFEF',
      t8: '#C1C5D0',
    },
  },
};

export default paletteColorsSettings;
