import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createRoot } from 'react-dom/client';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';
import Highcharts from 'highcharts';
import HCexporting from 'highcharts/modules/exporting';
import highchartsGantt from 'highcharts/modules/gantt';
import highchartsNoData from 'highcharts/modules/no-data-to-display';
import logger from '@app/core/logger/Logger';
import 'typeface-roboto'; // eslint-disable-line
import './core/theme/styles/index.scss';
import './core/validation/locale';
import * as serviceWorker from '@app/serviceWorker';
import { lang } from '@app/core/constants/chart';
import { store } from '@app/store';
import App from './App';
import './v2/shared/localization/i18n';

HCexporting(Highcharts);
highchartsGantt(Highcharts);
highchartsNoData(Highcharts);
Highcharts.setOptions({ lang });

logger.init();

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryParamProvider
        adapter={ReactRouter5Adapter}
        options={{
          searchStringToObject: parse,
          objectToSearchString: stringify,
        }}
      >
        <App />
        <ToastContainer />
      </QueryParamProvider>
    </BrowserRouter>
  </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
