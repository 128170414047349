import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Box, Icon, Typography, Tooltip } from '@mui/material';
import icons from '@app/assets/iconFont';
import DeleteButton from '@app/components/delete-table-row-by-id/DeleteButton';
import UpdateNoteButton from '@app/modules/map-module/components/map/notes/common/actions/UpdateNoteButton';
import { MapNoteType } from '@app/modules/map-module/components/map/notes/types';
import ImagesViewer from '@app/components/notes/NoteImagesViewer';
import mapNoteMutations from '@app/clients/apollo/requests/mutations/notes';
import { FULL_WIDTH, DATE_FORMAT } from '@app/v2/shared/constants';
import EllipsisTooltip from '@app/components/notes/EllipsisTooltip';
import MessageWithReadMore from '@app/components/notes/MessageWithReadMore';
import { getBackgroundColorForListing } from '@app/v2/shared/helpers';

const generateHeaders = (deps: {
  t: Function;
  showTime: any;
  classes: any;
  formatAddress: any;
  formatRoad: any;
  updateNoteButtonProps: any;
  onReset: any;
}) => {
  const { t, showTime, classes, formatAddress, formatRoad, updateNoteButtonProps, onReset } = deps;

  return [
    {
      value: values => (
        <Grid container justifyContent="space-between" alignItems="flex-start" pb={1}>
          <Grid item xs={8}>
            <EllipsisTooltip className={classes.titleText} text={values.title} placement="top" />
          </Grid>
          <Grid item xs="auto">
            <UpdateNoteButton
              {...updateNoteButtonProps}
              id={values.id}
              dialogData={{
                ...updateNoteButtonProps.dialogData,
                values,
              }}
            />
            <DeleteButton id={values.id} query={mapNoteMutations.delete} onDelete={onReset} />
          </Grid>
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container pb={1}>
          <MessageWithReadMore
            message={note.message}
            title={note.title}
            address={formatAddress(note.address, note.place.latitude, note.place.longitude)}
            roadAddress={`${note.road.titleFull} ${formatRoad(note.place.kilometer, note.place.meter)}`}
            date={showTime({ value: note.dateTime, options: { format: DATE_FORMAT.FORMAT_RU_POINT } })}
            time={showTime({ value: note.dateTime, options: { format: DATE_FORMAT.FORMAT_RU_HOURS } })}
            photo={note.photos}
            organization={note.organization.name}
            user={note.user.name}
            addressIcon={icons.pin}
            roadIcon={icons.road}
            dateIcon={icons.calender}
            timeIcon={icons.time}
            messageClassName={classes.messageText}
            buttonClassName={classes.readMoreButton}
            visibilityBox={note.visibility}
            UpdateNoteButtonComponent={UpdateNoteButton}
            updateNoteButtonProps={{
              ...updateNoteButtonProps,
              dialogData: { ...updateNoteButtonProps.dialogData, note },
              id: note.id,
            }}
          />
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: ({ place: { kilometer, meter, latitude, longitude }, road: { titleFull, code }, address }: MapNoteType) => (
        <Grid container className={classes.header} justifyContent="flex-start">
          <Grid item xs={7} className={classes.iconWithText}>
            <Icon className={classes.icon}>{icons.pin}</Icon>
            <Tooltip title={address} placement="left">
              <Typography variant="body2" className={classes.oneLineAddresses}>
                {formatAddress(null, latitude, longitude)}
              </Typography>
            </Tooltip>
          </Grid>
          <Grid item xs={5} className={classes.iconWithText}>
            <Icon className={classes.icon}>{icons.road}</Icon>
            <Tooltip title={`${titleFull}${formatRoad(kilometer, meter)}`} placement="right">
              <Typography variant="body2" className={classes.oneLineAddresses}>{`${code}${formatRoad(kilometer)}`}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container justifyContent="flex-start" spacing={1}>
          <Grid item className={classes.iconWithText}>
            <Icon className={classes.icon}>{icons.calender}</Icon>
            <Typography variant="body2" className={classes.oneLineDates}>
              {showTime({
                value: note.dateTime,
                options: {
                  format: DATE_FORMAT.FORMAT_RU_POINT,
                },
              })}
            </Typography>
          </Grid>
          <Grid item className={classes.iconWithText}>
            <Icon className={classes.icon}>{icons.time}</Icon>
            <Typography variant="body2" className={classes.oneLineDates}>
              {showTime({
                value: note.dateTime,
                options: {
                  format: DATE_FORMAT.FORMAT_RU_HOURS,
                },
              })}
            </Typography>
          </Grid>
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container justifyContent="center" alignItems="center" className={classes.imageContainer}>
          <ImagesViewer sliced images={note.photos} />
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container direction="column">
          <EllipsisTooltip className={classes.oneLineOrganizationUser} text={note.user.name} placement="bottom" />
          <EllipsisTooltip className={classes.oneLineOrganizationUser} text={note.organization.name} placement="bottom" />
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
    {
      value: note => (
        <Grid container pt={1}>
          <Tooltip title={`${t('form.noteVisibility')}: ${t(`form.visibilitySelect.${note.visibility}` as any)}`} placement="bottom">
            <Box width={FULL_WIDTH} sx={{ bgcolor: getBackgroundColorForListing(note.visibility) }} className={classes.visibilityBox} />
          </Tooltip>
        </Grid>
      ),
      label: '',
      width: FULL_WIDTH,
    },
  ];
};

export default generateHeaders;
