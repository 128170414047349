import React from 'react';
import { CLOSE_HEADER_HEIGHT, OPEN_HEADER_HEIGHT } from '@app/core/theme/constants';

interface PageLayoutStylesParameters {
  isHeaderRollUp: boolean;
  currentFooterHeight?: number;
}

const getPageLayoutSxStyles = ({ isHeaderRollUp, currentFooterHeight = 0 }: PageLayoutStylesParameters): { [key: string]: React.CSSProperties } => {
  const getHeight = (): string => {
    if (isHeaderRollUp) {
      return `calc(100vh - ${OPEN_HEADER_HEIGHT} - ${currentFooterHeight}px)`;
    }

    return `calc(100vh - ${CLOSE_HEADER_HEIGHT} - ${currentFooterHeight}px)`;
  };

  return {
    pageContent: {
      position: 'relative',
      height: getHeight(),
      display: 'flex',
      flexDirection: 'column',
      padding: '2px 0 0px 0',
    },
    pageFavouriteContent: {
      position: 'relative',
      height: 'calc(100vh - 64px - 8px)',
    },
  };
};

export default getPageLayoutSxStyles;
