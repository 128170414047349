import React, { useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Icon, Badge, Tooltip, Theme } from '@mui/material';
import icons from '@app/assets/iconFont';
import PositionType from '../../enums/PositionType';
import RoadDirectionType from '../../enums/RoadDirectionType';
import { CSDStationItem } from '../../types';
import { getXYCameraCoordinate, getCameraColor } from '../../helpers';
import { DEFAULT_STATION_SQUARE_SIZE, DEFAULT_STATION_SIZE, DEFAULT_FONT_SIZE } from '../../constants';

interface Props extends CSDStationItem, I18N.TranslationFunction<'common', 'notificationMessages'> {
  size?: number;
  squareSize?: number;
  fontSize?: number;
  isActive?: boolean;
}

const CSDStation = ({
  size = DEFAULT_STATION_SIZE,
  squareSize = DEFAULT_STATION_SQUARE_SIZE,
  fontSize = DEFAULT_FONT_SIZE,
  position = PositionType.top,
  direction,
  isExpired = false,
  isActive = false,
  stationId,
  hasHeighbor = false,
  isDublicate = false,
  onChange,
  t,
}: Props) => {
  const { translation, ...svgPositions } = getXYCameraCoordinate({ size, position, hasHeighbor, direction });
  const classes = useStyles({ size, squareSize, fontSize, direction, translation, isDublicate });

  const color = getCameraColor(isActive, isExpired);

  const hasDirection = Object.keys(RoadDirectionType).includes(direction);
  const hasPosition = Object.keys(PositionType).includes(position);

  const centerPosition = (size - squareSize) / 2;

  const translationMessage = useMemo(() => {
    switch (true) {
      case isDublicate:
        return t('stationSettingsOverlap');
      case !hasDirection && !hasPosition:
        return t('missingStationSettings');
      case !hasPosition:
        return t('missingStationPosition');
      case !hasDirection:
        return t('missingStationDirection');
      default:
        return null;
    }
  }, [isDublicate, hasDirection, hasPosition, t]);

  return (
    <svg width={size} height={size} {...svgPositions} overflow="visible" onClick={() => onChange(stationId)}>
      <g className={classes.iconTranslation}>
        <rect xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="transparent" rx="8px" />
        <rect
          xmlns="http://www.w3.org/2000/svg"
          x={centerPosition}
          y={centerPosition}
          width={squareSize}
          height={squareSize}
          rx="8px"
          fill={color}
          stroke="white"
          strokeWidth="1.2"
        />

        <foreignObject width={size} height={size}>
          <Tooltip title={translationMessage}>
            <Badge className={classes.badge} color="error" invisible={!!position} badgeContent="!">
              <Icon className={classes.icon}>{hasDirection ? icons.cameraRoad : icons.nobody}</Icon>
            </Badge>
          </Tooltip>
        </foreignObject>
      </g>
    </svg>
  );
};

const useStyles = makeStyles<Theme, Pick<Props, 'size' | 'squareSize' | 'fontSize' | 'direction' | 'isDublicate'> & { translation: string }>(() => ({
  icon: {
    '&.MuiIcon-root': {
      fontSize: ({ fontSize }) => fontSize,
      color: 'white',
      cursor: 'pointer',
      transform: ({ direction }) => (direction === RoadDirectionType.backward ? 'scaleX(-1)' : 'none'),
    },
  },
  badge: {
    '&.MuiBadge-root': {
      margin: ({ size, fontSize }) => (size - fontSize) / 2,
    },
  },
  iconTranslation: {
    transform: ({ translation, isDublicate }) => (!isDublicate ? translation : 'none'),
  },
}));

export default withTranslation('common', { keyPrefix: 'notificationMessages' })(CSDStation);
