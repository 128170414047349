import React, { Dispatch, FC, memo } from 'react';
import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { isAZPITemplateType, strictlyEqual } from '@app/v2/shared/helpers';
import { ReactComponent as RoadProtection } from '@app/assets/tpi/roadProtection.svg';
import { ReactComponent as ScreensBg } from '@app/assets/tpi/bgOfScreens.svg';
import { AZPIMode, VMSDeviceDirections, VMSDeviceTypeOfSupport, UsersAccess } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';
import CSDTPIDeviceDescriptions from './CSDTPIDeviceDescriptions';
import CSDTPIDeviceScreen from './CSDTPIDeviceScreen';

interface Props {
  activeId: number;
  isChangeable?: boolean;
  updateTemplateStatus: FC<Scoreboards.ChangeTemplateStatusProps>;
  setManualMode?: FC<Scoreboards.SetManualModeProps>;
  stations: Scoreboards.VMSStation[];
  fetchStation: Common.VoidCallBack;
  deviceType: VMSDeviceTypeOfSupport;
  currentDirection: VMSDeviceDirections;
  setDirection: Dispatch<VMSDeviceDirections>;
  setActive: Dispatch<Scoreboards.VMSStation>;
  descriptions: [string, string][];
  withBlur?: boolean;
}

const CSDTPIDevice = ({
  stations = [],
  activeId,
  deviceType,
  setDirection,
  descriptions = [],
  currentDirection,
  fetchStation,
  updateTemplateStatus: UpdateTemplateStatus,
  setManualMode: SetManualMode,
  ...screenProps
}: Props) => {
  const classes = useStyles({ deviceType, currentDirection });
  const { accessChecker } = useCheckAccess();

  return (
    <Stack className={classes.wrapper}>
      <Stack direction="row" className={classes.screens}>
        <ScreensBg />

        {stations.map(station => (
          <CSDTPIDeviceScreen
            key={station.id}
            station={station}
            azpiMode={station.manualSeconds ? AZPIMode.Active : AZPIMode.Deactive}
            isActive={strictlyEqual(station.id, activeId)}
            updateTemplateStatus={
              <UpdateTemplateStatus
                id={station.id}
                success={fetchStation}
                is_shutdown={!station?.is_shutdown ?? true}
                disabled={!accessChecker([UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT])}
              />
            }
            setManualMode={
              isAZPITemplateType(station.type) &&
              !station.manualSeconds && (
                <Box className={classes.manualModeContainer}>
                  <SetManualMode
                    stationId={station.id}
                    success={fetchStation}
                    deviceSize={{
                      width: station.device.settings.width,
                      height: station.device.settings.height,
                    }}
                  />
                </Box>
              )
            }
            {...screenProps}
          />
        ))}
      </Stack>

      <Box className={classes.bottom}>
        <RoadProtection />

        <CSDTPIDeviceDescriptions descriptions={descriptions} currentDirection={currentDirection} setDirection={setDirection} />
      </Box>
    </Stack>
  );
};

const useStyles = makeStyles<Theme, Pick<Props, 'deviceType' | 'currentDirection'>>(({ palette }) => ({
  wrapper: {
    width: '18.75rem',
    height: '10.563rem',
    maxWidth: '18.75rem',
    maxHeight: '10.563rem',
  },
  screens: {
    gap: '0.5rem',
    position: 'relative',
    alignItems: 'inherit',
    justifyContent: 'center',
    padding: '0.2rem 0.5rem',
    border: '3px solid black',
    height: 'calc(100% - 42%)',
    backgroundColor: palette.common.white,

    '& > svg': {
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    },
  },
  bottom: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-start',
    height: 'calc(100% - 60%)',
    backgroundColor: palette.common.white,
    justifyContent: ({ deviceType }) => {
      const position: Record<'right' | 'left' | 'center', string> = { right: 'flex-start', left: 'flex-end', center: 'center' };
      return position[deviceType];
    },
    borderRight: ({ deviceType }) => (strictlyEqual(deviceType, 'right') || strictlyEqual(deviceType, 'center')) && '10px solid black',
    borderLeft: ({ deviceType }) => (strictlyEqual(deviceType, 'left') || strictlyEqual(deviceType, 'center')) && '10px solid black',

    '& > svg': {
      top: '50%',
      left: '50%',
      position: 'absolute',
      transform: 'translate(-50%, -50%)',
    },
  },
  manualModeContainer: {
    top: '1.8rem',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default memo(CSDTPIDevice);
