import { useState, useCallback, useEffect } from 'react';
import axios from '@app/clients/axios/client';
import { showError } from '@app/core/utils/notifications';
import ErrorMessages from '@app/core/constants/errorsMessages';

const useFileHandler = (uploadPath, initialImages, setFieldValue) => {
  const [uploadedImages, setUploadedImages] = useState<string[]>(initialImages);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const uploadFiles = useCallback(async () => {
    if (selectedFiles.length === 0) {
      return;
    }

    const bodyFormData = new FormData();
    selectedFiles.forEach((file, index) => {
      bodyFormData.append(`file-${index}`, file);
    });

    try {
      const response = await axios.post(uploadPath, bodyFormData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const newPhotos = Object.keys(response.data).reduce((acc, key) => {
        const item = response.data[key];
        if (item.image) {
          acc.push(item.image);
        }
        return acc;
      }, []);

      setUploadedImages(prevImages => {
        const updatedPhotos = [...prevImages, ...newPhotos];
        setFieldValue('photos', updatedPhotos);
        return updatedPhotos;
      });
      setSelectedFiles([]);
    } catch (error) {
      showError(ErrorMessages.FILE_NOT_UPLOADED);
    }
  }, [selectedFiles, uploadPath, setFieldValue]);

  useEffect(() => {
    uploadFiles();
  }, [selectedFiles, uploadFiles]);

  const removeUploadedFile = useCallback(
    url => {
      setUploadedImages(prevImages => {
        const updatedImages = prevImages.filter(image => image !== url);
        setFieldValue('photos', updatedImages);
        return updatedImages;
      });
    },
    [setFieldValue],
  );

  const removeSelectedFile = useCallback(fileToRemove => {
    setSelectedFiles(prevFiles => prevFiles.filter(file => file !== fileToRemove));
  }, []);

  return {
    uploadedImages,
    selectedFiles,
    setUploadedImages,
    setSelectedFiles,
    uploadFiles,
    removeUploadedFile,
    removeSelectedFile,
  };
};

export default useFileHandler;
