import React, { useEffect, useMemo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { AppBar, Box, CssBaseline, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RouterConfig from '@app/v2/shared/configs/router';
import { useAppSelector } from '@app/v2/shared/hooks';
import ErrorPageSigns from '@app/assets/errorPageSigns.png';
import { DEFAULT_PAGE_FOR_REDIRECT, REDIRECT_AWAIT_TIME_VALUE } from '@app/v2/shared/constants';
import main from '@app/v2/shared/configs/main';

type Props = {
  handleTimeout: (path: string) => void;
} & WithTranslation<'components', 'errorPage'>;

const ErrorPage = ({ handleTimeout, t }: Props) => {
  const classes = useStyles();

  const startPageFromUserSettings = useAppSelector(state => state.account.settings.startPage) || DEFAULT_PAGE_FOR_REDIRECT;
  const redirectPath = useMemo(() => RouterConfig.find(({ name }) => name === startPageFromUserSettings).path, [startPageFromUserSettings]);

  useEffect(() => {
    const timeout = setTimeout((): void => {
      main.production && handleTimeout(redirectPath);
    }, REDIRECT_AWAIT_TIME_VALUE);

    return () => {
      clearTimeout(timeout);
    };
  }, [redirectPath, handleTimeout]);

  return (
    <>
      <CssBaseline />
      <AppBar className={classes.appBar} />
      <Box className={classes.wrapper}>
        <img src={ErrorPageSigns} alt="ErrorPageSigns" />
        <Box className={classes.textContainer}>
          <Typography variant="h4" marginBottom="1rem">
            {t('message4')}
          </Typography>
          <Typography variant="h5">
            {t('message1')}{' '}
            <Link underline="hover" href={`tel:${process.env.REACT_APP_SUPPORT_CONTACT_PHONE}`}>
              {process.env.REACT_APP_SUPPORT_CONTACT_PHONE}
            </Link>{' '}
            {t('message2')}{' '}
            <Link underline="hover" href={`mailto:${process.env.REACT_APP_SUPPORT_CONTACT_EMAIL}?subject=${t('message4')}`}>
              {process.env.REACT_APP_SUPPORT_CONTACT_EMAIL}
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  );
};

const useStyles = makeStyles(() => ({
  appBar: {
    position: 'fixed',
    height: '4rem',
  },
  wrapper: {
    height: '100vh',
    padding: '16px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    backgroundColor: '#fafafa',
  },
  image: {
    marginBottom: '2rem',
  },
  textContainer: {
    width: '60%',
    height: '40%',
    marginTop: '2rem',
  },
}));

export default withTranslation('components', { keyPrefix: 'errorPage' })(ErrorPage);
