import React, { MouseEvent, useState, ReactNode } from 'react';
import { PopperPlacementType, Box } from '@mui/material';
import { AlertStatuses } from '@app/v2/shared/enums';
import CSDMapMarkerPopover from '../CSDMapMarkerPopover';
import CSDMapMarkerBasic from '../CSDMapMarkerBasic/CSDMapMarkerBasic';

type Props = {
  iconName?: string;
  badgeColor?: string;
  markerType?: AlertStatuses;
  containerClassName?: string;
  notifications?: {
    iconName: string;
    tooltip?: string;
  }[];
  component?: ReactNode;
  placement?: PopperPlacementType;
};

const CSDMapMarkerSmall = ({ iconName, notifications = [], badgeColor = '#439BFF', containerClassName, markerType, component, placement }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpenContent = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(!anchorEl ? (event.target as HTMLDivElement) : null);
  };

  return (
    <Box className={containerClassName}>
      <CSDMapMarkerBasic
        iconName={iconName}
        notifications={notifications}
        badgeColor={badgeColor}
        onClick={handleOpenContent}
        markerType={markerType}
      />
      {Boolean(anchorEl) && Boolean(component) && <CSDMapMarkerPopover anchorEl={anchorEl} component={component} placement={placement} />}
    </Box>
  );
};

export default CSDMapMarkerSmall;
