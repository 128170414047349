import * as React from 'react';
import { useEffect, useRef } from 'react';
import { TableHead, TableBody, TableRow, Box } from '@mui/material';
import { SortStatus } from '@app/v2/shared/enums';
import { TableData, Row, Header } from './types';
import createTableRowClassList from './helpers';
import DataGridSortDefault from './DataGridSortDefault';
import CSDTable, { UNHIGHLIGHTED_ROW_INDEX } from '../../CSDTable/CSDTable';
import CSDTableCell from '../../CSDTableCells/CSDTableCell';
import CSDTableEmptyCell from '../../CSDTableCells/CSDTableEmptyCell';

interface Props<Data extends TableData> extends Table.CustomTableProps<any, Row<TableData>> {
  headerRows?: { [key: number]: Array<Header<Data> & { columnIndex: number }> };
  tableData: Row<Data>[];
  getRowCells: (index: number, data: Data) => React.ReactNode;
  scrollToRowCondition?: (row: Row<Data>) => boolean;
  onSorting?: (key: string, value: SortStatus) => void;
  sortingValue?: Common.Sorting;
}

const DataGridCSDTable = <T extends TableData>(
  { tableData, getRowCells, headerRows, onRowClick, scrollToRowCondition, onSorting, sortingValue, ...tableProps }: Props<T>,
  ref,
) => {
  const scrollRef = useRef<HTMLTableRowElement>(null);
  const isRefAssigned = useRef(false);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [tableData]);

  const tableHeaderRows = Object.entries(headerRows).map(([key, headerColumns]) => (
    <TableRow key={`data-grid-table-head-${key}`}>
      {headerColumns.map(({ field, headerName, renderHead, tooltip, thProps, columnIndex, isSortable = false, renderSort: RenderSort }) => {
        return (
          <CSDTableCell
            key={`data-grid-table-head-${field}`}
            label={tooltip}
            rowIndex={UNHIGHLIGHTED_ROW_INDEX}
            columnIndex={columnIndex}
            {...thProps}
          >
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0.5rem',
              }}
            >
              {renderHead || headerName || field}
              {isSortable && RenderSort && <RenderSort sorting={sortingValue} sortKey={field} onSorting={onSorting} />}
              {isSortable && !RenderSort && <DataGridSortDefault sorting={sortingValue} sortKey={field} onSorting={onSorting} />}
            </Box>
          </CSDTableCell>
        );
      })}
    </TableRow>
  ));

  return (
    <CSDTable ref={ref} {...tableProps}>
      <TableHead>{tableHeaderRows}</TableHead>
      <TableBody>
        {tableData.length ? (
          tableData.map((item, index) => {
            const shouldAssignRef = scrollToRowCondition && scrollToRowCondition(item) && !isRefAssigned.current;

            if (shouldAssignRef) {
              isRefAssigned.current = true;
            }

            return (
              <TableRow
                key={`data-grid-table-row-${item.key || item.id || index}`}
                className={createTableRowClassList(item, scrollToRowCondition && scrollToRowCondition(item))}
                ref={shouldAssignRef ? scrollRef : null}
                onClick={() => {
                  if (typeof onRowClick === 'function') onRowClick(item);
                }}
              >
                {getRowCells(index, item as T)}
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <CSDTableEmptyCell colSpan={headerRows[0].length} />
          </TableRow>
        )}
      </TableBody>
    </CSDTable>
  );
};

export default React.forwardRef(DataGridCSDTable);
