import moment, { Moment } from 'moment';
import {
  DataTestIds,
  Groups,
  LocatorReportKeys,
  LocatorReportPayload,
  LocatorReportsSubGroups,
  MeteoReportKeys,
  MeteoReportPayload,
  MeteoReportsSubGroups,
  MeteoServicesReportKeys,
  MeteoServicesReportsSubGroups,
  ReportDatesComponentsTypes,
  ReportFormat,
  ReportSetupComponentsTypes,
  ReportsGroups,
  ReportsPreviewMod,
  ReportsSections,
  ReportType,
  TrafficReportDirections,
  TrafficReportKeys,
  TrafficReportPayload,
  TrafficReportPeriods,
  TrafficReportsSubGroups,
  TrafficServicesReportKeys,
  TrafficServicesReportsSubGroups,
  UsersAccess,
  VideoServicesReportKeys,
  VideoServicesReportsSubGroups,
} from '@app/v2/shared/enums';
import { i18n } from '@app/v2/shared/localization';
import icons from '@app/assets/iconFont';
import { DATE_PERIOD } from './date';

export const AUTO_CLOSE_REPORTS_NOTIFICATIONS_TIME = 5000;
export const DEFAULT_REPORT_ZOOM_VALUE = 250;

export const reportFormats: { label: string; value: ReportFormat; dataTestId: DataTestIds }[] = [
  { label: 'Excel', value: ReportFormat.XLSX, dataTestId: DataTestIds.ReportSetupFormatExcelBtn },
  { label: 'PDF', value: ReportFormat.PDF, dataTestId: DataTestIds.ReportSetupFormatPDFBtn },
];

export const reportModuleTitles: Record<ReportsSections, string> = {
  [ReportsSections.Common]: i18n.t('reports:moduleTitle'),
  [ReportsSections.Services]: i18n.t('reports:moduleTitleServices'),
};

export const initReportKeyForGroup: Partial<Record<ReportType, string>> = {
  [ReportType.Meteo]: MeteoReportKeys.OBSERVATIONS_HISTORY,
  [ReportType.Traffic]: TrafficReportKeys.ODMMonth,
  [ReportType.ServiceMeteo]: MeteoServicesReportKeys.MALFUNCTIONS_BY_ORGS,
  [ReportType.ServiceVideo]: VideoServicesReportKeys.MALFUNCTIONS_BY_ORGS,
  [ReportType.ServiceTraffic]: TrafficServicesReportKeys.MALFUNCTIONS_BY_ORGS,
  [ReportType.Locator]: LocatorReportKeys.REPORT_PERIOD,
};

export const initialParametersValue = {
  [ReportsGroups.Main]: {
    dateFrom: null,
    dateTo: null,
    orgIds: [],
    roadIds: [],
    placeIds: [],
    format: ReportFormat.XLSX,
  },
  [ReportsGroups.Additional]: {},
};

export const reportsWithOneStations: string[] = [
  TrafficReportKeys.ConsolidatedYearDaysAndHoursWithGroups,
  TrafficReportKeys.ConsolidatedYearDaysAndHours,
  TrafficReportKeys.IntensityHourSpeedFiveMinutes,
];

export const reportsWithoutStations: string[] = [LocatorReportKeys.REPORT_PERIOD, LocatorReportKeys.REPORT_MONTH, LocatorReportKeys.REPORT_YEAR];

export const COMMON_MIN_DATE = moment().year(2000).startOf('year');
export const MIN_DATE_LOCATOR_ANALYZE = moment().year(2018).startOf('year');

export const datesLimitsByReportsGroups: Partial<Record<ReportType, Record<'min' | 'max', Moment>>> = {
  [ReportType.Locator]: { min: MIN_DATE_LOCATOR_ANALYZE, max: moment() },
};

export const reportsErrorsByType: Partial<Record<ReportType, Record<string, string>>> = {
  [ReportType.Locator]: {
    chooseEntity: i18n.t('reports:messages.chooseOrgOrRoad'),
    [LocatorReportKeys.REPORT_MONTH]: i18n.t('reports:messages.monthReportNotRequested'),
    [LocatorReportKeys.REPORT_YEAR]: i18n.t('reports:messages.yearReportNotRequested'),
  },
  [ReportType.Traffic]: {
    [TrafficReportPeriods.HOUR]: i18n.t('reports:messages.periodOver31days'),
    [TrafficReportPeriods.DAY]: i18n.t('reports:messages.periodOver365days'),
    [TrafficReportPeriods.WEEK]: i18n.t('reports:messages.periodOver365days'),
  },
};

export const reportsSubGroupsTitles: Partial<Record<ReportType, Record<string, string>>> = {
  [ReportType.Meteo]: {
    [MeteoReportsSubGroups.ObservationsHistory]: i18n.t('reports:meteo.subGroup.obsHistory'),
    [MeteoReportsSubGroups.ExtremeValues]: i18n.t('reports:meteo.subGroup.extValues'),
  },
  [ReportType.Traffic]: {
    [TrafficReportsSubGroups.Universal]: i18n.t('reports:traffic.subGroup.Universal'),
    [TrafficReportsSubGroups.ODM]: i18n.t('reports:traffic.subGroup.ODM'),
    [TrafficReportsSubGroups.Years]: i18n.t('reports:traffic.subGroup.years'),
    [TrafficReportsSubGroups.Months]: i18n.t('reports:traffic.subGroup.month'),
    [TrafficReportsSubGroups.Day]: i18n.t('reports:traffic.subGroup.day'),
    [TrafficReportsSubGroups.Hours]: i18n.t('reports:traffic.subGroup.hours'),
    [TrafficReportsSubGroups.FiveMinutes]: i18n.t('reports:traffic.subGroup.fiveMinutes'),
    [TrafficReportsSubGroups.TotalQuantity]: i18n.t('reports:traffic.subGroup.totalQuantity'),
  },
  [ReportType.Locator]: {
    [LocatorReportsSubGroups.Period]: i18n.t('reports:locator.subGroup.period'),
    [LocatorReportsSubGroups.Months]: i18n.t('reports:locator.subGroup.month'),
    [LocatorReportsSubGroups.Years]: i18n.t('reports:locator.subGroup.year'),
  },
  [ReportType.ServiceMeteo]: {
    [MeteoServicesReportsSubGroups.Malfunctions]: i18n.t('reports:meteoServices.subGroup.malfunctions'),
    [MeteoServicesReportsSubGroups.RO]: i18n.t('reports:meteoServices.subGroup.ro'),
    [MeteoServicesReportsSubGroups.DiagnosticSensors]: i18n.t('reports:meteoServices.subGroup.diagnosticSensors'),
  },
  [ReportType.ServiceVideo]: {
    [VideoServicesReportsSubGroups.Malfunctions]: i18n.t('reports:videoServices.subGroup.malfunctions'),
    [VideoServicesReportsSubGroups.RO]: i18n.t('reports:videoServices.subGroup.ro'),
  },
  [ReportType.ServiceTraffic]: {
    [TrafficServicesReportsSubGroups.Malfunctions]: i18n.t('reports:trafficServices.subGroup.malfunctions'),
    [TrafficServicesReportsSubGroups.RO]: i18n.t('reports:trafficServices.subGroup.ro'),
  },
};

/** Meteo */

export const meteoReports: Reports.Model[] = [
  {
    group: ReportsGroups.Main,
    subGroup: [MeteoReportsSubGroups.ObservationsHistory],
    type: MeteoReportKeys.OBSERVATIONS_HISTORY,
    url: `/reports/common/meteo/${MeteoReportKeys.OBSERVATIONS_HISTORY}`,
    label: i18n.t('reports:meteo.type.observationsHistory'),
    dateType: ReportDatesComponentsTypes.FullInterval,
    isTypeAndGroupHaveTheSameLabels: true,
  },
  {
    group: ReportsGroups.Main,
    subGroup: [MeteoReportsSubGroups.ExtremeValues],
    type: MeteoReportKeys.EXTREME_VALUES,
    url: `/reports/common/meteo/${MeteoReportKeys.EXTREME_VALUES}`,
    label: i18n.t('reports:meteo.type.extremeValues'),
    dateType: ReportDatesComponentsTypes.FullInterval,
    isTypeAndGroupHaveTheSameLabels: true,
  },
];

export const meteoReportsSetup: Reports.ReportSetup = {
  [MeteoReportKeys.OBSERVATIONS_HISTORY]: {
    [ReportsGroups.Main]: null,
    [ReportsGroups.Additional]: [
      {
        icon: icons.tempAir,
        group: Groups.Temperature,
        payload: MeteoReportPayload.TAir,
        label: i18n.t('reports:meteo.extraPoints.isAirTemp'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.temperature'),
      },
      {
        icon: icons.tempSurface,
        group: Groups.Temperature,
        payload: MeteoReportPayload.TRoad,
        label: i18n.t('reports:meteo.extraPoints.isRoadTemp'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.temperature'),
      },
      {
        icon: icons.tempSurfaceTwo,
        group: Groups.Temperature,
        payload: MeteoReportPayload.TRoad2,
        label: i18n.t('reports:meteo.extraPoints.isRoadTemp2'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.temperature'),
      },
      {
        group: Groups.Temperature,
        icon: icons.tempBelowSurface,
        payload: MeteoReportPayload.TUnderRoad,
        label: i18n.t('reports:meteo.extraPoints.isDeepRoadTemp'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.temperature'),
      },
      {
        icon: icons.tempBelowSurfaceTwo,
        group: Groups.Temperature,
        payload: MeteoReportPayload.TUnderRoad2,
        label: i18n.t('reports:meteo.extraPoints.isDeepRoadTemp2'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.temperature'),
      },
      {
        icon: icons.tempDewPoint,
        group: Groups.Temperature,
        payload: MeteoReportPayload.TDewPoint,
        label: i18n.t('reports:meteo.extraPoints.isDewPoint'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.temperature'),
      },

      {
        payload: MeteoReportPayload.WindSpeed,
        icon: icons.windSpeed,
        group: Groups.Wind,
        label: i18n.t('reports:meteo.extraPoints.isWindSpeed'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.wind'),
      },
      {
        payload: MeteoReportPayload.WindDirection,
        icon: icons.windDirection,
        label: i18n.t('reports:meteo.extraPoints.isWindDirectionGrad'),
        group: Groups.Wind,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.wind'),
      },
      {
        payload: MeteoReportPayload.WindDirGr,
        icon: icons.windGusts,
        label: i18n.t('reports:meteo.extraPoints.isWindDirection1'),
        group: Groups.Wind,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.wind'),
      },

      {
        payload: MeteoReportPayload.Visibility,
        icon: icons.visibility,
        label: i18n.t('reports:meteo.extraPoints.isVisibility'),
        group: Groups.Other,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.other'),
      },
      {
        icon: icons.pressure,
        group: Groups.Other,
        payload: MeteoReportPayload.Pressure,
        label: i18n.t('reports:meteo.extraPoints.isPressure'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.other'),
      },
      {
        icon: icons.humidity,
        group: Groups.Other,
        payload: MeteoReportPayload.Humidity,
        label: i18n.t('reports:meteo.extraPoints.isHumidity'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.other'),
      },
      {
        payload: MeteoReportPayload.SkyState,
        icon: icons.sky,
        label: i18n.t('reports:meteo.extraPoints.isSkyState'),
        group: Groups.Other,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.other'),
      },

      {
        payload: MeteoReportPayload.StatusDamp,
        icon: icons.surfaceConditionOne,
        label: i18n.t('reports:meteo.extraPoints.isRoadState'),
        group: Groups.Precipitation,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.precipitation'),
      },
      {
        payload: MeteoReportPayload.StatusDamp2,
        icon: icons.surfaceConditionTwo,
        label: i18n.t('reports:meteo.extraPoints.isRoadState2'),
        group: Groups.Precipitation,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.precipitation'),
      },
      {
        payload: MeteoReportPayload.WaterHeight,
        icon: icons.layerThickness,
        label: i18n.t('reports:meteo.extraPoints.isWaterHeight'),
        group: Groups.Precipitation,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.precipitation'),
      },
      {
        payload: MeteoReportPayload.PrecipitationType,
        icon: icons.typeRain,
        label: i18n.t('reports:meteo.extraPoints.isPrecipitationType'),
        group: Groups.Precipitation,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.precipitation'),
      },
      {
        payload: MeteoReportPayload.PrecipitationIntensity,
        icon: icons.precipitationIntensity,
        label: i18n.t('reports:meteo.extraPoints.isPrecipitationIntensity'),
        group: Groups.Precipitation,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.precipitation'),
      },
      {
        payload: MeteoReportPayload.PrecipitationInstant,
        icon: icons.sumRain,
        label: i18n.t('reports:meteo.extraPoints.isPrecipitationInstant'),
        group: Groups.Precipitation,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.precipitation'),
      },

      {
        icon: icons.tempFreezing,
        group: Groups.DeicingAgents,
        payload: MeteoReportPayload.TFreezingPoint,
        label: i18n.t('reports:meteo.extraPoints.isFreezingPoint'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.deicingAgents'),
      },
      {
        icon: icons.tempFreezingTwo,
        group: Groups.DeicingAgents,
        payload: MeteoReportPayload.TFreezingPoint2,
        label: i18n.t('reports:meteo.extraPoints.isFreezingPoint2'),
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.deicingAgents'),
      },
      {
        payload: MeteoReportPayload.PMGP,
        icon: icons.deicingAgents,
        label: i18n.t('reports:meteo.extraPoints.isPMGP'),
        group: Groups.DeicingAgents,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.deicingAgents'),
      },
      {
        payload: MeteoReportPayload.PMGP2,
        icon: icons.deicingAgentsOne,
        label: i18n.t('reports:meteo.extraPoints.isPMGP2'),
        group: Groups.DeicingAgents,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.deicingAgents'),
      },
      {
        payload: MeteoReportPayload.PMG,
        icon: icons.deicingAgentsTwo,
        label: i18n.t('reports:meteo.extraPoints.isPMG'),
        group: Groups.DeicingAgents,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.deicingAgents'),
      },
      {
        payload: MeteoReportPayload.PMG2,
        icon: icons.deicingAgentsThree,
        label: i18n.t('reports:meteo.extraPoints.isPMG2'),
        group: Groups.DeicingAgents,
        groupLabel: i18n.t('reports:meteo.extraPointsGroup.deicingAgents'),
      },
    ].map(setup => ({
      ...setup,
      initialValue: true,
      type: ReportSetupComponentsTypes.IconButton,
    })),
  },
};

/** Traffic */

export const trafficReports: Reports.Model[] = [
  {
    group: ReportsGroups.Main,
    subGroup: [TrafficReportsSubGroups.ODM],
    type: TrafficReportKeys.ODMMonth,
    url: `/reports/common/traffic/${TrafficReportKeys.ODMMonth}`,
    label: i18n.t('reports:traffic.type.odmMonth'),
    dateType: ReportDatesComponentsTypes.Month,
  },
  {
    group: ReportsGroups.Main,
    subGroup: [TrafficReportsSubGroups.ODM],
    type: TrafficReportKeys.ODMYear,
    url: `/reports/common/traffic/${TrafficReportKeys.ODMYear}`,
    label: i18n.t('reports:traffic.type.odmYear'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Main,
    subGroup: [TrafficReportsSubGroups.ODM],
    type: TrafficReportKeys.ODMMonthConversion,
    url: `/reports/common/traffic/${TrafficReportKeys.ODMMonthConversion}`,
    label: i18n.t('reports:traffic.type.odmMonthConversion'),
    dateType: ReportDatesComponentsTypes.Month,
  },
  {
    group: ReportsGroups.Main,
    subGroup: [TrafficReportsSubGroups.ODM],
    type: TrafficReportKeys.ODMYearConversion,
    url: `/reports/common/traffic/${TrafficReportKeys.ODMYearConversion}`,
    label: i18n.t('reports:traffic.type.odmYearConversion'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Years],
    type: TrafficReportKeys.ConsolidatedYearDaysAndHours,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedYearDaysAndHours}`,
    label: i18n.t('reports:traffic.type.sumYearHour'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Hours],
    type: TrafficReportKeys.IntensityHourSpeed,
    url: `/reports/common/traffic/${TrafficReportKeys.IntensityHourSpeed}`,
    label: i18n.t('reports:traffic.type.intensHourAndSpeed'),
    dateType: ReportDatesComponentsTypes.FullInterval,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.FiveMinutes],
    type: TrafficReportKeys.IntensityHourSpeedFiveMinutes,
    url: `/reports/common/traffic/${TrafficReportKeys.IntensityHourSpeedFiveMinutes}`,
    label: i18n.t('reports:traffic.type.intensHourAndSpeed5Minutes'),
    dateType: ReportDatesComponentsTypes.FiveMinutes,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.ConsolidatedYearDaysAndHoursWithGroups,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedYearDaysAndHoursWithGroups}`,
    label: i18n.t('reports:traffic.type.sumYearHourGroup'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.TotalQuantity, TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.ConsolidatedYears,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedYears}`,
    label: i18n.t('reports:traffic.type.consolidatedYears'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.TotalQuantity],
    type: TrafficReportKeys.ConsolidatedYear,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedYear}`,
    label: i18n.t('reports:traffic.type.consolidatedYear'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.AverageValuesYears,
    url: `/reports/common/traffic/${TrafficReportKeys.AverageValuesYears}`,
    label: i18n.t('reports:traffic.type.averageValuesYears'),
    dateType: ReportDatesComponentsTypes.Year,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.TotalQuantity, TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.ConsolidatedMonth,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedMonth}`,
    label: i18n.t('reports:traffic.type.consolidatedMonth'),
    dateType: ReportDatesComponentsTypes.Month,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.TotalQuantity, TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.ConsolidatedGrowthMonth,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedGrowthMonth}`,
    label: i18n.t('reports:traffic.type.consolidatedGrowthMonth'),
    dateType: ReportDatesComponentsTypes.Month,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.TotalQuantity, TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.ConsolidatedMaxMonth,
    url: `/reports/common/traffic/${TrafficReportKeys.ConsolidatedMaxMonth}`,
    label: i18n.t('reports:traffic.type.consolidatedMaxMonth'),
    dateType: ReportDatesComponentsTypes.Month,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.Intensity,
    url: `/reports/common/traffic/${TrafficReportKeys.Intensity}`,
    label: i18n.t('reports:traffic.type.intens'),
    dateType: ReportDatesComponentsTypes.FullInterval,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Hours],
    type: TrafficReportKeys.AverageIntensityByHour,
    url: `/reports/common/traffic/${TrafficReportKeys.AverageIntensityByHour}`,
    label: i18n.t('reports:traffic.type.avgHourDay'),
    dateType: ReportDatesComponentsTypes.Date,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Hours],
    type: TrafficReportKeys.DailySummaryByHour,
    url: `/reports/common/traffic/${TrafficReportKeys.DailySummaryByHour}`,
    label: i18n.t('reports:traffic.type.dailySummary'),
    dateType: ReportDatesComponentsTypes.Date,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Hours],
    type: TrafficReportKeys.IntensityHour,
    url: `/reports/common/traffic/${TrafficReportKeys.IntensityHour}`,
    label: i18n.t('reports:traffic.type.intensHour'),
    dateType: ReportDatesComponentsTypes.FullInterval,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.IntensityDirection,
    url: `/reports/common/traffic/${TrafficReportKeys.IntensityDirection}`,
    label: i18n.t('reports:traffic.type.directions'),
    dateType: ReportDatesComponentsTypes.FullInterval,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.TotalQuantity, TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.Period,
    url: `/reports/common/traffic/${TrafficReportKeys.Period}`,
    label: i18n.t('reports:traffic.type.summaryPeriod'),
    dateType: ReportDatesComponentsTypes.FullInterval,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Day],
    type: TrafficReportKeys.Thunderous,
    url: `/reports/common/traffic/${TrafficReportKeys.Thunderous}`,
    label: i18n.t('reports:traffic.type.thunderous'),
    dateType: ReportDatesComponentsTypes.FiveMinutes,
  },
  {
    group: ReportsGroups.Additional,
    subGroup: [TrafficReportsSubGroups.Universal],
    type: TrafficReportKeys.Universal,
    url: `/reports/common/traffic/${TrafficReportKeys.Universal}`,
    label: i18n.t('reports:traffic.type.universal'),
    dateType: ReportDatesComponentsTypes.Universal,
    isTypeAndGroupHaveTheSameLabels: true,
  },
];

const commonMainTrafficSetup: Reports.ReportSetupItem[] = [
  {
    label: i18n.t('reports:traffic.extraPoints.isFullDay'),
    type: ReportSetupComponentsTypes.CheckBox,
    payload: TrafficReportPayload.FullDay,
    initialValue: false,
  },
  {
    label: i18n.t('reports:traffic.extraPoints.isMMZPoint'),
    type: ReportSetupComponentsTypes.CheckBox,
    payload: TrafficReportPayload.MMZPoint,
    initialValue: false,
    dependsFields: [TrafficReportPayload.NotMMZPoint],
  },
  {
    label: i18n.t('reports:traffic.extraPoints.isNotMMZPoint'),
    type: ReportSetupComponentsTypes.CheckBox,
    payload: TrafficReportPayload.NotMMZPoint,
    initialValue: false,
    dependsFields: [TrafficReportPayload.MMZPoint],
  },
];

export const trafficReportsSetup: Reports.ReportSetup = {
  [TrafficReportKeys.ODMMonth]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ODMYear]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.AverageIntensityByHour]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.IntensityDirection]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.IntensityHourSpeed]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.IntensityHourSpeedFiveMinutes]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedYearDaysAndHoursWithGroups]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedYearDaysAndHours]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.DailySummaryByHour]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.Intensity]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.IntensityHour]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.Period]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.Thunderous]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedYear]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedYears]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.AverageValuesYears]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedMonth]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedGrowthMonth]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.ConsolidatedMaxMonth]: {
    [ReportsGroups.Main]: commonMainTrafficSetup,
    [ReportsGroups.Additional]: null,
  },
  [TrafficReportKeys.Universal]: {
    [ReportsGroups.Main]: [
      {
        label: i18n.t('reports:traffic.extraPoints.direction'),
        type: ReportSetupComponentsTypes.Select,
        payload: TrafficReportPayload.Direction,
        selectItems: [
          {
            label: i18n.t('reports:traffic.extraPoints.direction.twice'),
            value: TrafficReportDirections.BOTH,
          },
          {
            label: i18n.t('reports:traffic.extraPoints.direction.forward'),
            value: TrafficReportDirections.FORWARD,
          },
          {
            label: i18n.t('reports:traffic.extraPoints.direction.backward'),
            value: TrafficReportDirections.BACKWARD,
          },
        ],
        initialSelectedValue: TrafficReportDirections.BOTH,
      },
      {
        label: i18n.t('reports:traffic.extraPoints.periods'),
        type: ReportSetupComponentsTypes.Select,
        payload: TrafficReportPayload.Periods,
        selectItems: [
          {
            label: i18n.t('reports:traffic.extraPoints.periods.hour'),
            value: TrafficReportPeriods.HOUR,
            disabledPeriod: DATE_PERIOD.HOUR,
          },
          {
            label: i18n.t('reports:traffic.extraPoints.periods.day'),
            value: TrafficReportPeriods.DAY,
            disabledPeriod: DATE_PERIOD.DAY,
          },
          {
            label: i18n.t('reports:traffic.extraPoints.periods.week'),
            value: TrafficReportPeriods.WEEK,
            disabledPeriod: DATE_PERIOD.WEEK,
          },
          {
            label: i18n.t('reports:traffic.extraPoints.periods.month'),
            value: TrafficReportPeriods.MONTH,
            disabledPeriod: DATE_PERIOD.MONTH,
          },
          {
            label: i18n.t('reports:traffic.extraPoints.periods.year'),
            value: TrafficReportPeriods.YEAR,
            disabledPeriod: DATE_PERIOD.YEAR,
          },
        ],
        initialSelectedValue: TrafficReportPeriods.MONTH,
      },
      {
        label: i18n.t('reports:traffic.extraPoints.isTotalRange'),
        payload: TrafficReportPayload.TotalRange,
        initialValue: false,
        type: ReportSetupComponentsTypes.CheckBox,
      },
    ],
    [ReportsGroups.Additional]: null,
  },
};

/** Locator */

export const locatorReports: Reports.Model[] = [
  {
    group: ReportsGroups.Main,
    type: LocatorReportKeys.REPORT_PERIOD,
    url: `/reports/common/locator/${LocatorReportKeys.REPORT_PERIOD}`,
    dateType: ReportDatesComponentsTypes.FullInterval,
    label: i18n.t('reports:locator.type.period'),
    subGroup: [LocatorReportsSubGroups.Period],
    access: [UsersAccess.PAGE_LOCATOR_ANALYSIS],
    isTypeAndGroupHaveTheSameLabels: true,
  },
  {
    group: ReportsGroups.Main,
    type: LocatorReportKeys.REPORT_MONTH,
    url: `/reports/common/locator/${LocatorReportKeys.REPORT_MONTH}`,
    dateType: ReportDatesComponentsTypes.Month,
    label: i18n.t('reports:locator.type.month'),
    subGroup: [LocatorReportsSubGroups.Months],
    access: [UsersAccess.PAGE_LOCATOR_ANALYSIS],
    isTypeAndGroupHaveTheSameLabels: true,
  },
  {
    group: ReportsGroups.Main,
    type: LocatorReportKeys.REPORT_YEAR,
    url: `/reports/common/locator/${LocatorReportKeys.REPORT_YEAR}`,
    dateType: ReportDatesComponentsTypes.Year,
    label: i18n.t('reports:locator.type.year'),
    subGroup: [LocatorReportsSubGroups.Years],
    access: [UsersAccess.PAGE_LOCATOR_ANALYSIS],
    isTypeAndGroupHaveTheSameLabels: true,
  },
];

const commonLocatorsMainSetup: Reports.ReportSetupItem[] = [
  {
    label: i18n.t('filters:additional.hideOutdatedData'),
    type: ReportSetupComponentsTypes.CheckBox,
    payload: LocatorReportPayload.FullData,
    initialValue: true,
  },
  {
    label: i18n.t('filters:additional.showDataWithoutLocators'),
    type: ReportSetupComponentsTypes.CheckBox,
    payload: LocatorReportPayload.IsLocator,
    initialValue: false,
  },
  {
    label: i18n.t('filters:additional.showAdms'),
    type: ReportSetupComponentsTypes.CheckBox,
    payload: LocatorReportPayload.IsAdms,
    initialValue: false,
  },
];

export const locatorReportsSetup: Reports.ReportSetup = {
  [LocatorReportKeys.REPORT_PERIOD]: {
    main: commonLocatorsMainSetup,
  },
  [LocatorReportKeys.REPORT_MONTH]: {
    main: commonLocatorsMainSetup,
  },
  [LocatorReportKeys.REPORT_YEAR]: {
    main: commonLocatorsMainSetup,
  },
};

/** Meteo services */

export const meteoServicesReports: Reports.Model[] = [
  {
    group: ReportsGroups.Main,
    type: MeteoServicesReportKeys.MALFUNCTIONS_BY_ORGS,
    dateType: ReportDatesComponentsTypes.FullInterval,
    url: `/reports/services/serviceMeteo/${MeteoServicesReportKeys.MALFUNCTIONS_BY_ORGS}`,
    label: i18n.t('reports:meteoServices.type.malfunctionsByOrgs'),
    subGroup: [MeteoServicesReportsSubGroups.Malfunctions],
  },
  {
    group: ReportsGroups.Main,
    type: MeteoServicesReportKeys.RO_CALENDAR,
    dateType: ReportDatesComponentsTypes.FullInterval,
    url: `/reports/services/serviceMeteo/${MeteoServicesReportKeys.RO_CALENDAR}`,
    label: i18n.t('reports:meteoServices.type.roCalendar'),
    subGroup: [MeteoServicesReportsSubGroups.RO],
  },
  {
    group: ReportsGroups.Main,
    type: MeteoServicesReportKeys.DIAGNOSTIC_SENSORS,
    dateType: ReportDatesComponentsTypes.FullInterval,
    url: `/reports/services/serviceMeteo/${MeteoServicesReportKeys.DIAGNOSTIC_SENSORS}`,
    label: i18n.t('reports:meteoServices.type.diagnosticSensors'),
    subGroup: [MeteoServicesReportsSubGroups.DiagnosticSensors],
    previewType: ReportsPreviewMod.Last24Hours,
    isTypeAndGroupHaveTheSameLabels: true,
  },
];

/** Traffic services */

export const trafficServicesReports: Reports.Model[] = [
  {
    group: ReportsGroups.Main,
    url: `/reports/services/serviceTraffic/${TrafficServicesReportKeys.MALFUNCTIONS_BY_ORGS}`,
    dateType: ReportDatesComponentsTypes.FullInterval,
    type: TrafficServicesReportKeys.MALFUNCTIONS_BY_ORGS,
    label: i18n.t('reports:trafficServices.type.malfunctionsByOrgs'),
    subGroup: [TrafficServicesReportsSubGroups.Malfunctions],
  },
  {
    group: ReportsGroups.Main,
    url: `/reports/services/serviceTraffic/${TrafficServicesReportKeys.RO_CALENDAR}`,
    dateType: ReportDatesComponentsTypes.FullInterval,
    type: TrafficServicesReportKeys.RO_CALENDAR,
    label: i18n.t('reports:trafficServices.type.roCalendar'),
    subGroup: [TrafficServicesReportsSubGroups.RO],
  },
];

export const trafficServicesReportsSetup: Reports.ReportSetup = {
  [TrafficServicesReportKeys.MALFUNCTIONS_BY_ORGS]: {
    main: commonMainTrafficSetup,
  },
  [TrafficServicesReportKeys.RO_CALENDAR]: {
    main: commonMainTrafficSetup,
  },
};

/** Video services */

export const videoServicesReports: Reports.Model[] = [
  {
    group: ReportsGroups.Main,
    url: `/reports/services/serviceVideo/${VideoServicesReportKeys.MALFUNCTIONS_BY_ORGS}`,
    dateType: ReportDatesComponentsTypes.FullInterval,
    type: VideoServicesReportKeys.MALFUNCTIONS_BY_ORGS,
    label: i18n.t('reports:videoServices.type.malfunctionsByOrgs'),
    subGroup: [VideoServicesReportsSubGroups.Malfunctions],
  },
  {
    group: ReportsGroups.Main,
    url: `/reports/services/serviceVideo/${VideoServicesReportKeys.RO_CALENDAR}`,
    dateType: ReportDatesComponentsTypes.FullInterval,
    type: VideoServicesReportKeys.RO_CALENDAR,
    label: i18n.t('reports:videoServices.type.roCalendar'),
    subGroup: [VideoServicesReportsSubGroups.RO],
  },
];

/** All reports */

export const allReports = {
  [ReportsSections.Common]: {
    [ReportType.Meteo]: meteoReports,
    [ReportType.Traffic]: trafficReports,
    [ReportType.Locator]: locatorReports,
  },
  [ReportsSections.Services]: {
    [ReportType.ServiceMeteo]: meteoServicesReports,
    [ReportType.ServiceVideo]: videoServicesReports,
    [ReportType.ServiceTraffic]: trafficServicesReports,
  },
};

/** All setup */

export const allSetups = {
  [ReportType.Meteo]: meteoReportsSetup,
  [ReportType.Traffic]: trafficReportsSetup,
  [ReportType.Locator]: locatorReportsSetup,
  [ReportType.ServiceTraffic]: trafficServicesReportsSetup,
};
