import { FooterTabs, MainPaths, UsersAccess } from '@app/v2/shared/enums';

const layoutConfig: Partial<Record<MainPaths, Partial<StaticConfigs.Layout>>> = {
  [MainPaths.Index]: {
    permissionScopes: [
      UsersAccess.PAGE_HOME,
      UsersAccess.PAGE_VIDEO,
      UsersAccess.PAGE_METEO,
      UsersAccess.PAGE_TRAFFIC,
      UsersAccess.PAGE_JOURNALS,
      UsersAccess.PAGE_REFERENCES,
      UsersAccess.PAGE_MAP,
      UsersAccess.MAP_LOCATORS,
      UsersAccess.MAP_LOCATORS_ALL_LAYERS,
      UsersAccess.MAP_ROAD_OBJECTS,
    ],
  },
  [MainPaths.Meteo]: {
    permissionScopes: [UsersAccess.PAGE_METEO],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewInfoBadgeWithForecast: true,
      isViewReportBtn: true,
      isViewRoadsSegments: true,
    },
    footerConfig: {
      footerTabs: [FooterTabs.TableDataSetup],
      defaultTab: FooterTabs.TableDataSetup,
    },
  },
  [MainPaths.Video]: {
    permissionScopes: [UsersAccess.PAGE_VIDEO],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewVideoWall: true,
      isViewInfoBadgeWithForecast: true,
      isViewPagination: true,
    },
    footerConfig: {
      footerTabs: [FooterTabs.VideoWallSettings],
      defaultTab: FooterTabs.VideoWallSettings,
      isOpenByDefault: false,
    },
  },
  [MainPaths.Traffic]: {
    permissionScopes: [UsersAccess.PAGE_TRAFFIC],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewReportBtn: true,
      isViewRoadsSegments: true,
    },
    footerConfig: {
      footerTabs: [FooterTabs.TableDataSetup],
      defaultTab: FooterTabs.TableDataSetup,
    },
  },
  [MainPaths.AccidentNews]: {
    permissionScopes: [UsersAccess.PAGE_NEWS, UsersAccess.SUPERVISOR],
    headerConfig: {
      isViewFilters: false,
      isViewFiltersPresets: false,
      isViewRoadsSegments: false,
    },
  },
  [MainPaths.ECOMonitoring]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
  },
  [MainPaths.AdaptationToClimateChange]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.ROOT, UsersAccess.SUPERVISOR, UsersAccess.PAGE_DPGT],
    footerConfig: {
      footerTabs: [FooterTabs.TableDataSetup],
      defaultTab: FooterTabs.TableDataSetup,
    },
  },
  [MainPaths.CityWeatherForecast]: {
    permissionScopes: [UsersAccess.SPECGIDROMET],
  },
  [MainPaths.SOPS]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewRoadsSegments: true,
    },
    permissionScopes: [UsersAccess.ROOT, UsersAccess.PAGE_SAPO, UsersAccess.PAGE_SOPS],
    footerConfig: {
      footerTabs: [FooterTabs.TableDataSetup],
      defaultTab: FooterTabs.TableDataSetup,
    },
  },
  [MainPaths.GuidesUsers]: {
    permissionScopes: [UsersAccess.PAGE_REFERENCES],
  },
  [MainPaths.GuidesRoads]: {
    permissionScopes: [UsersAccess.PAGE_REFERENCES],
  },
  [MainPaths.Notifications]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
  },
  [MainPaths.LogFloodEffects]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogHazard]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.GuidesOrganizations]: {
    permissionScopes: [UsersAccess.PAGE_REFERENCES],
  },
  [MainPaths.LogBreaks]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogTrafficRestriction]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogForcesAttracting]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogEmergencySituationFacility]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogEmergencySituationBuilding]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogEmergencySituation]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.Favorites]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewFavoritesLayouts: true,
    },
  },
  [MainPaths.LogAccident]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.LogDuty]: {
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    permissionScopes: [UsersAccess.PAGE_JOURNALS],
  },
  [MainPaths.ScoreboardSigns]: {
    permissionScopes: [UsersAccess.ROOT],
    headerConfig: {
      isViewFilters: false,
      isViewFiltersPresets: false,
      isViewScoreboardsBlockLinks: true,
    },
  },
  [MainPaths.ScoreboardPlaylists]: {
    permissionScopes: [UsersAccess.ROOT],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewScoreboardsBlockLinks: true,
    },
    filterConfig: {
      isViewPresets: true,
      isDisabledRoads: true,
      isDisabledPlaces: true,
      filterPermissionScopes: [UsersAccess.ROOT],
    },
  },
  [MainPaths.ScoreboardsAndSigns]: {
    permissionScopes: [UsersAccess.VMS_VIEW_ONLY, UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
  },
  [MainPaths.ScoreboardConfiguration]: {
    permissionScopes: [UsersAccess.ROOT],
    headerConfig: {
      isViewFilters: false,
      isViewFiltersPresets: false,
      isViewScoreboardsBlockLinks: true,
    },
  },
  [MainPaths.ScoreboardDevices]: {
    permissionScopes: [UsersAccess.SERVICE_ENGINEER],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewScoreboardsBlockLinks: true,
    },
  },
  [MainPaths.ScoreboardTemplates]: {
    permissionScopes: [UsersAccess.ROOT],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewScoreboardsBlockLinks: true,
    },
    filterConfig: {
      isViewPresets: true,
      isDisabledPlaces: true,
      isDisabledRoads: true,
      filterPermissionScopes: [UsersAccess.ROOT],
    },
  },
  [MainPaths.VideoManagement]: {
    permissionScopes: [UsersAccess.PAGE_SERVICES],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewReportBtn: true,
    },
  },
  [MainPaths.Map]: {
    permissionScopes: [UsersAccess.PAGE_MAP],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    footerConfig: {
      footerTabs: [FooterTabs.Meteo, FooterTabs.MeteoLocators, FooterTabs.MapSymbols, FooterTabs.Weather, FooterTabs.Notes],
      defaultTab: FooterTabs.Meteo,
    },
  },
  [MainPaths.MeteoManagement]: {
    permissionScopes: [UsersAccess.PAGE_SERVICES],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewReportBtn: true,
    },
  },
  [MainPaths.TrafficManagement]: {
    permissionScopes: [UsersAccess.PAGE_SERVICES],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewReportBtn: true,
    },
  },
  [MainPaths.VideoWaterLevelControl]: {
    permissionScopes: [UsersAccess.ROOT, UsersAccess.SUPERVISOR, UsersAccess.PAGE_SCLR],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewInfoBadgeWithForecast: true,
    },
    filterConfig: {
      isDisabledPlaces: true,
    },
    footerConfig: {
      footerTabs: [FooterTabs.WaterLevelControlSettings],
      defaultTab: FooterTabs.WaterLevelControlSettings,
      isOpenByDefault: false,
    },
  },
  [MainPaths.LocatorAnalysis]: {
    permissionScopes: [UsersAccess.PAGE_LOCATOR_ANALYSIS],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewInfoBadgeWithForecast: false,
      isViewReportBtn: true,
    },
    filterConfig: {
      isDisabledPlaces: true,
    },
  },
  [MainPaths.StationDetails]: {
    permissionScopes: [UsersAccess.PAGE_METEO],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
      isViewRoadsSegments: true,
    },
    footerConfig: {
      footerTabs: [FooterTabs.TableDataSetup],
      defaultTab: FooterTabs.TableDataSetup,
    },
  },
  [MainPaths.Incidents]: {
    permissionScopes: [UsersAccess.VIDEO_ACCIDENTS],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
  },
  [MainPaths.FederalRoads]: {
    permissionScopes: [UsersAccess.ROOT],
    headerConfig: {
      isViewFilters: false,
      isViewFiltersPresets: false,
      isViewRoadsSegments: false,
    },
  },
  [MainPaths.FederalRoadsSegments]: {
    permissionScopes: [UsersAccess.ROOT],
    headerConfig: {
      isViewFilters: false,
      isViewFiltersPresets: false,
      isViewRoadsSegments: false,
    },
  },
  [MainPaths.Dashboard]: {
    permissionScopes: [UsersAccess.PAGE_DASHBOARD],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    filterConfig: {
      isDisabledPlaces: true,
    },
  },
  [MainPaths.MapNotes]: {
    permissionScopes: [UsersAccess.PAGE_NOTES],
    headerConfig: {
      isViewFilters: true,
      isViewFiltersPresets: true,
    },
    filterConfig: {
      isDisabledPlaces: true,
    },
  },
};

export default layoutConfig;
