import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import vmsQueries from '@app/clients/apollo/requests/queries/vms';
import { VmsManagement } from '@app/v2/widgets/postDialog/types';

export default function useManagement(): [boolean] {
  const { data } = useQuery<{ vms: VmsManagement }>(vmsQueries.vmsStationsManagement);

  const isDisabled = useMemo(() => {
    return data?.vms?.stations?.meta?.isManagement === false;
  }, [data]);
  return [isDisabled];
}
