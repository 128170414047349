import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';
import waterLevelChartReduser from '@app/store/slices/waterLevelChart/waterLevelChartSlice';
import logger from '@app/core/logger/Logger';
import tokenStorage from '@app/clients/apollo/tokenStorage';
import authReducer from '@store/slices/auth';
import accountReducer from '@store/slices/account';
import chatReducer from '@store/slices/chat';
import filtersReducer from '@store/slices/filters';
import loaderReducer from '@store/slices/loader';
import subscriptionsReducer from '@store/slices/subscriptions';
import viewTableElementsReducer from '@store/slices/viewTableElements';
import mapReducer from '@store/slices/map';
import systemReducer from '@store/slices/system';
import meteoChartReducer from '@store/slices/meteoChart/meteoChartSlice';
import meteoChartConstructorReducer from '@store/slices/meteoChartConstructor/meteoChartConstructorSlice';
import trafficChartSlice from '@store/slices/trafficChart/trafficChartSlice';
import videoPostSlice from '@store/slices/videoPost/videoPostSlice';
import newFiltersSlice from '@store/slices/newFilters/newFiltersSlice';
import favoritesLayoutsSlice from '@store/slices/favoritesLayouts/favoritesLayoutsSlice';
import newUserSetsSlice from '@store/slices/newUserSets/newUserSetsSlice';
import federalRoadsSlice from '@store/slices/federalRoads/federalRoadsSlice';
import sortingSlice from '@store/slices/sorting/index';

const sentryReduxEnhancer = logger.createReduxEnhancer({
  stateTransformer: (state: RootState) => ({
    ...state,
    auth: { ...state.auth, token: 'User token' },
    filters: { ...state.filters, filterDictionary: 'Removed because value is large' },
  }),
});

export const initialPreloadedState = {
  auth: {
    isAuth: tokenStorage.isExistAuthToken,
    token: tokenStorage.get(),
    isConfirmInProgress: false,
    authErrorStatus: false,
    authErrorStatusMessage: null,
    confirmationErrorStatus: false,
    confirmationErrorMessage: null,
    passwordRecoveryStatus: null,
  },
};

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  chat: chatReducer,
  filters: filtersReducer,
  newFilters: newFiltersSlice,
  newUserSets: newUserSetsSlice,
  loader: loaderReducer,
  subscriptions: subscriptionsReducer,
  viewTableElements: viewTableElementsReducer,
  map: mapReducer,
  system: systemReducer,
  meteoChart: meteoChartReducer,
  meteoChartConstructor: meteoChartConstructorReducer,
  waterLevelChart: waterLevelChartReduser,
  videoPost: videoPostSlice,
  trafficChart: trafficChartSlice,
  favoritesLayouts: favoritesLayoutsSlice,
  federalRoads: federalRoadsSlice,
  sorting: sortingSlice,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    enhancers: [sentryReduxEnhancer],
    preloadedState,
  });

export const store = setupStore(initialPreloadedState);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
