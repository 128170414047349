import React, { memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { CircularProgress, CircularProgressProps, SelectProps } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { CSDSelect } from '@app/modules/kit-module/shared/ui';
import vmsNewQueries from '@app/clients/apollo/requests/queries/vmsNew';
import vmsNewMutations from '@app/clients/apollo/requests/mutations/vmsNew';
import { showSuccess } from '@app/core/utils/notifications';
import { EMPTY_STRING } from '@app/v2/shared/constants';
import { addFeatureActions } from '@app/v2/shared/utils';
import { UsersAccess } from '@app/v2/shared/enums';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { filterSelectorsBySize } from '@app/v2/shared/helpers';

interface Props extends Common.FeaturesActions {
  stationId: number;
  disabled?: boolean;
  playlistsSelectors?: Scoreboards.Selector[];
  selectProps?: SelectProps;
}

const CSDChangePlaylistForDevice = ({
  id,
  stationId,
  playlistsSelectors = [],
  settings = null,
  disabled = false,
  selectProps = {},
  ...featuresCbs
}: Props & Pick<Scoreboards.Playlist, 'id'> & Pick<Scoreboards.Device, 'settings'>) => {
  const { accessChecker } = useCheckAccess();

  const { data, loading: fetchLoading } = useQuery<Scoreboards.PlaylistsSelectorsResponse>(vmsNewQueries.playlistsSelectors, {
    skip: !!playlistsSelectors.length,
  });

  const [changePlaylist, { loading: mutationLoading }] = useMutation<Common.MutationResponse, Scoreboards.AttachPlaylistFromStationVariables>(
    vmsNewMutations.playlist.add,
  );

  const items = useMemo<Common.SelectorValues>(
    () =>
      filterSelectorsBySize(data?.vmsNew?.selectors?.playlists ?? playlistsSelectors, {
        height: settings.height,
        width: settings.width,
      }),
    [data?.vmsNew?.selectors?.playlists, playlistsSelectors, settings],
  );

  const handleChange = (nextActivePlaylistId: string) => {
    addFeatureActions(
      changePlaylist({
        variables: { stationId, playlistId: Number(nextActivePlaylistId) },
      }).then(({ data: { message } }) => showSuccess(message)),
      featuresCbs,
    );
  };

  const loading = fetchLoading || mutationLoading;

  const isSelectDisabled = disabled || !items.length || loading || !accessChecker([UsersAccess.VMS_LIMIT_EDIT, UsersAccess.VMS_FULL_EDIT]);

  return (
    <CSDSelect
      items={items}
      value={id ?? EMPTY_STRING}
      handleChange={handleChange}
      disabled={isSelectDisabled}
      {...(loading && {
        IconComponent: () => <StyledCircularProgress size="1.5rem" />,
      })}
      withTooltips
      {...selectProps}
    />
  );
};

const StyledCircularProgress = styled(CircularProgress)<CircularProgressProps>(() => ({
  position: 'absolute',
  right: '0.5rem',
}));

export default memo(CSDChangePlaylistForDevice);
