import React, { ChangeEvent, Dispatch, FC, memo, ReactNode, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Divider, Icon, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles/createTheme';
import moment, { Moment } from 'moment';
import clsx from 'clsx';
import icons from '@app/assets/iconFont';
import { formatAPI, formatRuPoint } from '@app/core/helpers/dateFormat';
import { CSDDateRangePickerColumnAntd, CSDInputStandard, CSDScoreboardsViewer, CSDSelect, CSDTimePiker } from '@app/modules/kit-module/shared/ui';
import { boxShadowDark, boxShadowLight } from '@theme/styles';
import { isLightTheme, isMessageCard, isSignCard, preparedSelectValues, strictlyEqual } from '@app/v2/shared/helpers';
import { useCheckAccess } from '@app/v2/shared/hooks';
import { DATE_FORMAT, durationValues } from '@app/v2/shared/constants';
import { CardsViewMode, DataTestIds, UsersAccess, ViewTypes, VMSTemplatesTypes } from '@app/v2/shared/enums';
import CSDTPICardEdit from './CSDTPICardEdit';
import CSDTPICardActionPanel from './CSDTPICardActionPanel';

enum Content {
  EditButton,
  ActionPanel,
  Title,
  Dates,
  Interval,
  Times,
}

interface Props extends I18N.TranslationFunction<'scoreboard'> {
  playlist: Scoreboards.PlaylistModel;
  template: Scoreboards.TemplateWithModeAndId;
  type?: VMSTemplatesTypes;
  onUpdate?: Common.VoidCallBack;
  onChangeActiveCard?: Dispatch<number>;
  onChangeActiveStatus?: (mode: CardsViewMode, templateId?: number) => void;
  updatePlaylist?: FC<Scoreboards.UpdatePlaylistProps>;
  deleteTemplateFrom?: FC<Scoreboards.DeleteTemplateFromProps>;
  updateTemplateContent?: FC<Scoreboards.UpdateTemplateContentProps>;
}

const CSDTPICard = ({
  template,
  playlist,
  onUpdate,
  type,
  onChangeActiveCard,
  onChangeActiveStatus,
  updatePlaylist: UpdatePlaylist,
  deleteTemplateFrom: DeleteTemplateFrom,
  updateTemplateContent: UpdateTemplateContent,
  t,
}: Props) => {
  const { accessChecker } = useCheckAccess();
  const cardRef = useRef<HTMLDivElement | null>(null);

  const [localTemplate, setLocalTemplate] = useState<Scoreboards.TemplateWithModeAndId>(template);

  const classes = useStyles({ currentMode: template.mode });

  const changeHandlers: Partial<Record<Content, Common.AnyFunction>> = {
    [Content.Title]: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
      setLocalTemplate(prev => ({
        ...prev,
        template: { ...prev.template, title: event.target.value },
      })),
    [Content.Dates]: ([nextDateFrom, nextDateTo]: [Moment, Moment]) =>
      setLocalTemplate(prev => ({
        ...prev,
        dateFrom: formatAPI(nextDateFrom),
        dateTo: formatAPI(nextDateTo.endOf('day')),
      })),
    [Content.Times]: (nextStartTime: string, nextEndTime: string) => {
      setLocalTemplate(prev => ({
        ...prev,
        startTime: nextStartTime,
        endTime: nextEndTime,
      }));
    },
    [Content.Interval]: (nextDurationValue: string) => setLocalTemplate(prev => ({ ...prev, duration: Number(nextDurationValue) })),
  };

  const content: Partial<Record<Content, Record<CardsViewMode, ReactNode>>> = {
    [Content.EditButton]: {
      [CardsViewMode.View]: (
        <CSDTPICardEdit
          onEdit={() => {
            onChangeActiveCard && onChangeActiveCard(template.id);
            onChangeActiveStatus(CardsViewMode.Edit, template.id);
          }}
        />
      ),
      [CardsViewMode.Edit]: null,
    },
    [Content.ActionPanel]: {
      [CardsViewMode.View]: null,
      [CardsViewMode.Edit]: (
        <CSDTPICardActionPanel
          onClose={() => {
            setLocalTemplate(template);
            onChangeActiveStatus(CardsViewMode.View);
          }}
          updatePlaylist={
            <>
              {UpdatePlaylist && (
                <UpdatePlaylist
                  playlist={playlist}
                  template={localTemplate}
                  success={onUpdate}
                  after={() => onChangeActiveStatus(CardsViewMode.View)}
                />
              )}
            </>
          }
          deleteTemplate={
            <>
              {DeleteTemplateFrom && (
                <DeleteTemplateFrom
                  playlist={playlist}
                  template={localTemplate}
                  success={onUpdate}
                  after={() => onChangeActiveStatus(CardsViewMode.View)}
                />
              )}
            </>
          }
        />
      ),
    },
    [Content.Title]: {
      [CardsViewMode.View]: <Typography className="template-title">{template.template.title}</Typography>,
      [CardsViewMode.Edit]: (
        <CSDInputStandard
          hideLabel
          inputProps={{ style: { fontSize: '1rem', padding: '0 0 0.4rem 0' } }}
          size="small"
          value={localTemplate.template.title}
          onChange={changeHandlers[Content.Title]}
        />
      ),
    },
    [Content.Dates]: {
      [CardsViewMode.View]: (
        <>
          <Icon>{icons.calender}</Icon>
          <Stack>
            <Typography variant="body2">{formatRuPoint(template.dateFrom)}</Typography>
            <Typography variant="body2">{formatRuPoint(template.dateTo)}</Typography>
          </Stack>
        </>
      ),
      [CardsViewMode.Edit]: (
        <CSDDateRangePickerColumnAntd
          style={{ maxWidth: '5rem' }}
          value={[moment(localTemplate.dateFrom), moment(localTemplate.dateTo)]}
          format={DATE_FORMAT.FORMAT_RU_POINT}
          onChange={changeHandlers[Content.Dates]}
        />
      ),
    },
    [Content.Times]: {
      [CardsViewMode.View]: (
        <>
          <Icon>{icons.time}</Icon>
          <Stack>
            <Typography variant="body2">{template.startTime}</Typography>
            <Typography variant="body2">{template.endTime}</Typography>
          </Stack>
        </>
      ),
      [CardsViewMode.Edit]: (
        <CSDTimePiker startTime={localTemplate.startTime} endTime={localTemplate.endTime} handleChange={changeHandlers[Content.Times]} />
      ),
    },
    [Content.Interval]: {
      [CardsViewMode.View]: (
        <Typography variant="body2">{durationValues.find(({ value }) => value === template.duration)?.label ?? template.duration}</Typography>
      ),
      [CardsViewMode.Edit]: (
        <CSDSelect
          variant="standard"
          size="small"
          value={localTemplate.duration}
          items={preparedSelectValues({ values: durationValues, t })}
          handleChange={changeHandlers[Content.Interval]}
        />
      ),
    },
  };

  return (
    <Box ref={cardRef} className={classes.wrapper} data-testid={DataTestIds.VMSCard}>
      {accessChecker([UsersAccess.VMS_FULL_EDIT, UsersAccess.VMS_LIMIT_EDIT]) && content[Content.EditButton][template.mode]}

      {content[Content.ActionPanel][template.mode]}

      <Box className={classes.labelWrapper}>
        <Typography className={classes.label}>{t('playlists.previewRowData.label')}</Typography>

        {content[Content.Title][template.mode]}

        {!strictlyEqual(template.mode, CardsViewMode.Edit) && <Divider sx={{ borderColor: 'black' }} flexItem />}
      </Box>

      <Box
        className={clsx({
          [classes.messageImgAndTimeWrapper]: isMessageCard(type),
          [classes.signImgAndTimeWrapper]: isSignCard(type),
        })}
      >
        <Box
          className={clsx(classes.scoreboardsViewerContainer, {
            [classes.scoreboardsViewerMessageContainer]: isMessageCard(type),
            [classes.scoreboardsViewerSignContainer]: isSignCard(type),
          })}
        >
          {!template?.template || Array.isArray(template.template) ? (
            <Typography>{t('playlists.previewRowData.noPreview')}</Typography>
          ) : (
            <>
              {UpdateTemplateContent && accessChecker([UsersAccess.VMS_FULL_EDIT, UsersAccess.VMS_LIMIT_EDIT]) && (
                <UpdateTemplateContent template={template.template} success={onUpdate} />
              )}
              <CSDScoreboardsViewer
                fullSize
                fullWidth
                isDrawCells={false}
                viewType={ViewTypes.Img}
                template={template.template}
                templateType={VMSTemplatesTypes.Message}
              />
            </>
          )}
        </Box>

        <Box
          className={clsx(classes.time, {
            [classes.messageTime]: isMessageCard(type),
            [classes.signTime]: isSignCard(type),
          })}
        >
          <Box>{content[Content.Dates][template.mode]}</Box>
          <Box>{content[Content.Times][template.mode]}</Box>

          <Box>
            <Icon>{icons.interval}</Icon>
            {content[Content.Interval][template.mode]}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { currentMode: CardsViewMode }>(({ palette }) => ({
  wrapper: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
    minWidth: '18rem',
    maxWidth: '18rem',
    minHeight: '15rem',
    maxHeight: '15rem',
    position: 'relative',
    transition: 'all, 0.5 easy',
    justifyContent: 'space-between',
    zIndex: ({ currentMode }) => (strictlyEqual(currentMode, CardsViewMode.Edit) ? 10 : 1),
    borderRadius: ({ currentMode }) => (strictlyEqual(currentMode, CardsViewMode.Edit) ? '0.5rem 0 0.5rem 0.5rem' : '0.5rem'),
    border: ({ currentMode }) => `1px solid ${strictlyEqual(currentMode, CardsViewMode.Edit) ? palette.common.black : palette.text.t2}`,
    boxShadow: ({ currentMode }) => strictlyEqual(currentMode, CardsViewMode.Edit) && (isLightTheme(palette.mode) ? boxShadowLight : boxShadowDark),

    '&:hover': {
      boxShadow: ({ currentMode }) =>
        !strictlyEqual(currentMode, CardsViewMode.Edit) && (isLightTheme(palette.mode) ? boxShadowLight : boxShadowDark),
    },
  },
  labelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: ({ currentMode }) => (strictlyEqual(currentMode, CardsViewMode.Edit) ? '0.25rem' : '0.5rem'),

    '& > p': {
      textAlign: 'left',
    },
  },
  label: {
    '&.MuiTypography-root': {
      color: palette.text.t2,
    },
  },
  messageImgAndTimeWrapper: {
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  signImgAndTimeWrapper: {
    height: '100%',
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  time: {
    display: 'flex',

    '& > div': {
      gap: '0.5rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },

    '&.MuiTypography-root': {
      lineHeight: '1rem',
    },

    '& .custom-icon': {
      '&.MuiIcon-root': {
        fontSize: '1rem',
      },
    },
  },
  messageTime: {
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& > div': {
      flexBasis: '33%',
      minWidth: '33%',
      maxWidth: '33%',
    },
  },
  signTime: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: '0.5rem',
  },
  scoreboardsViewerContainer: {
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100%',

    '& > button': {
      position: 'absolute',
      top: '0.5rem',
      right: '0.5rem',

      '& .custom-icon': {
        color: 'white',
        fontSize: '1rem',
      },
    },
  },
  scoreboardsViewerMessageContainer: {
    minHeight: '6.25rem',
    maxHeight: '6.25rem',
  },
  scoreboardsViewerSignContainer: {
    minHeight: '8.5rem',
    maxHeight: '8.5rem',
  },
}));

export default withTranslation('scoreboard')(memo(CSDTPICard));
