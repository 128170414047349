import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import { useMemo } from 'react';
import BaseDialog from '@mui/material/Dialog';
import { DialogContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DialogRef } from '@app/core/providers/DialogProvider';
import 'react-image-gallery/styles/css/image-gallery.css';

export type Image = {
  original: string;
  thumbnail: string;
};

type Props = {
  images: string[];
  startIndex: number;
} & DialogRef<string>;

const Dialog = ({ close, images, startIndex }: Props) => {
  const classes = useStyles();

  const imagesObjects = useMemo(
    () =>
      images.map(path => ({
        original: path,
        thumbnail: `${path}?size=250x150`,
        originalClass: classes.image,
      })),
    [images, classes],
  );

  return (
    <BaseDialog open maxWidth="sm" fullWidth scroll="paper" onClose={() => close()}>
      <DialogContent>
        <ImageGallery showPlayButton={false} showFullscreenButton={false} items={imagesObjects} startIndex={startIndex} />
      </DialogContent>
    </BaseDialog>
  );
};

export default Dialog;

const useStyles = makeStyles(() => ({
  image: {
    '& img': {
      height: '50vh',
      width: '50vh',
    },
  },
}));
