import React, { useContext, ReactNode } from 'react';
import clsx from 'clsx';
import { TableCell, TableCellProps, Tooltip, TooltipProps } from '@mui/material';
import { TableHighlightContext, UNHIGHLIGHTED_COLUMN_INDEX, UNHIGHLIGHTED_ROW_INDEX } from '../CSDTable/CSDTable';

export interface HighlightedCell {
  columnIndex: number;
  rowIndex: number;
}

interface Props extends HighlightedCell, TableCellProps {
  label?: ReactNode;
  className?: string;
  children?: React.ReactElement | string;
  tooltipProps?: Partial<TooltipProps>;
}

const CSDTableCell = ({ label, children, className, tooltipProps, rowIndex, columnIndex, ...cellProps }: Props) => {
  const tableContext = useContext(TableHighlightContext);

  const onMouseEnter = event => {
    if (event.currentTarget.closest('thead')) return;

    tableContext.setHighlightedCell({
      column: columnIndex,
      row: rowIndex,
    });
  };

  const onMouseLeave = () => {
    tableContext.setHighlightedCell({
      column: UNHIGHLIGHTED_COLUMN_INDEX,
      row: UNHIGHLIGHTED_ROW_INDEX,
    });
  };

  // All cells in row before hovered element, and all cells in column higher that hovered element
  const isCellInScopeOfHighlighting =
    (tableContext.row === rowIndex && tableContext.column >= columnIndex) || (tableContext.column === columnIndex && tableContext.row >= rowIndex);

  return (
    <TableCell
      align="center"
      className={clsx(className, { hightlight: isCellInScopeOfHighlighting })}
      style={{ lineHeight: 0 }}
      {...cellProps}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {label ? (
        <Tooltip title={label} arrow {...tooltipProps}>
          {typeof children === 'string' ? <span>{children}</span> : children}
        </Tooltip>
      ) : (
        children
      )}
    </TableCell>
  );
};

export default CSDTableCell;
