import * as React from 'react';
import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { useMutation } from '@apollo/client';
import Dialog from '@app/components/common/dialog/Dialog';
import { DialogRef } from '@app/core/providers/DialogProvider';
import { showError, showSuccess } from '@app/core/utils/notifications';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import ErrorMessages from '@app/core/constants/errorsMessages';
import useDialog from '@app/core/hooks/useDialog';
import { CSDConfirmDialog } from '@app/modules/kit-module/shared/ui';

type Props<T> = {
  item: T;
  dialogData: DialogData<T>;
} & DialogRef;

const UpdateNoteDialog = <T extends { id?: number }>({ close, beforeClose, item, dialogData: { component, schema, title, mutation } }: Props<T>) => {
  const formik = useRef<FormikProps<T>>(null);
  const [confirmResult, setConfirmResult] = useState<boolean>(false);
  const { t } = useTranslation('map', { keyPrefix: 'notes.form' });

  const [updateNote, { loading }] = useMutation(mutation);
  const openConfirmDialog = useDialog(CSDConfirmDialog);

  const onSubmit = async (v: T) => {
    const data = schema.validateSync(v);

    if (item.id === undefined || item.id === null) {
      throw new Error(ErrorMessages.INCORRECT_UPDATING);
    }
    try {
      const {
        data: {
          note: {
            update: { message },
          },
        },
      } = await updateNote({ variables: { data: { ...data, id: undefined, roadAddress: undefined }, id: item.id } });

      showSuccess(message);
      close();
    } catch (e) {
      if (e instanceof Error) {
        showError(e.message);
      } else {
        throw e;
      }
    }
  };

  const handleConfirmClose = useCallback(() => {
    const confirmDialogRef = openConfirmDialog({ question: t('confirmationQuestion') });
    confirmDialogRef.beforeClose.subscribe((confirmClose: boolean) => {
      setConfirmResult(confirmClose);
    });
  }, [openConfirmDialog, t]);

  const handleClose = () => {
    if (formik.current && formik.current.dirty) {
      handleConfirmClose();
    } else {
      close();
    }
  };

  useEffect(() => {
    if (confirmResult) {
      close();
    }
  }, [confirmResult, close]);

  return (
    <Dialog
      handleSubmit={() => formik.current.handleSubmit()}
      title={title}
      maxWidth="xs"
      fullWidth
      close={handleClose}
      beforeClose={beforeClose}
      loading={loading}
    >
      <Formik innerRef={formik} initialValues={item} validationSchema={schema} onSubmit={onSubmit} component={component} />
    </Dialog>
  );
};

export default UpdateNoteDialog;
