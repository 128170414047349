import * as React from 'react';
import { useMemo } from 'react';
import ListingPage from '@app/components/listing-page/ListingPage';
import useDialog from '@app/core/hooks/useDialog';
import ListingAddNoteDialog from '@app/modules/map-module/components/dialogs/ListingAddNoteDialog';
import { MapNoteInput } from '@app/modules/map-module/components/map/notes/types';
import { DialogData } from '@app/components/common/dialog/types/DialogData';
import ListingNotesCards from '@app/components/notes/ListingNotesCards';
import useGenerateHeaders from '@app/components/notes/hooks/useGenerateHeaders';
import NotesCarousel from '@app/components/notes/NotesCarousel';
import { HORIZONTAL_LISTING_NOTES_HEIGHT } from '@app/v2/shared/constants';
import CSDCreateNoteButton from '@app/components/notes/CSDCreateNoteButton';
import MapNotesDataSource from '../../data/MapNotesDataSource';

const MapNotesListing = ({ isHorizontal = false }: { isHorizontal?: boolean }) => {
  const dataSource = useMemo(() => new MapNotesDataSource(), []);
  const headers = useGenerateHeaders(dataSource);
  const mapDialog = useDialog<{ dialogData: DialogData<MapNoteInput> }>(ListingAddNoteDialog);

  return (
    <ListingPage
      style={isHorizontal ? { height: HORIZONTAL_LISTING_NOTES_HEIGHT } : undefined}
      dataSource={dataSource}
      settingsPanel={!isHorizontal ? () => <CSDCreateNoteButton handleAdd={mapDialog} /> : undefined}
      dataTable={({ handleVirtualizedScroll, listingData, loading }) =>
        isHorizontal ? (
          <NotesCarousel handleVirtualizedScroll={handleVirtualizedScroll} listingData={listingData} headers={headers} loading={loading} />
        ) : (
          <ListingNotesCards handleVirtualizedScroll={handleVirtualizedScroll} listingData={listingData} headers={headers} loading={loading} />
        )
      }
    />
  );
};

export default MapNotesListing;
