import DataSource from '@app/core/source/DataSource';
import { showError } from '@app/core/utils/notifications';
import { MapNoteType } from '@app/modules/map-module/components/map/notes/types';
import mapNoteQueries from '@app/clients/apollo/requests/queries/notes';

export type Response<T> = {
  note: { listing: Common.ResponseDataStation<T> };
};

export default class MapNotesDataSource extends DataSource<MapNoteType> {
  async getData<P extends Common.Params>({ filters, pagination }: Common.GetDataOptions<P>): Promise<Common.ResponseDataStation<MapNoteType>> {
    const safeFilters = filters || {};
    const response = await this.client
      .query<Response<MapNoteType>>({
        query: mapNoteQueries.notes,
        variables: {
          page: pagination.page,
          limit: pagination.limit,
          roadIds: safeFilters.roads || [],
          organizationIds: safeFilters.organizations || [],
          dateTo: safeFilters.dateTo,
          dateFrom: safeFilters.dateFrom,
        },
      })
      .catch(e => showError(e.message));

    if (response && response?.data?.note?.listing) {
      const {
        data: {
          note: {
            listing: { data, total },
          },
        },
      } = response;

      const listingData = { data, total };

      listingData.data = listingData.data.map(item => ({
        ...item,
        place: { ...item?.place, id: item.id, address: item?.place?.kilometer, meters: item?.place?.meter },
      }));

      listingData.data.sort((firstNote, nextNote) => new Date(nextNote.dateTime).getTime() - new Date(firstNote.dateTime).getTime());

      return listingData;
    }

    return { data: [], total: 0 };
  }
}
