import { MapNoteInput, MapNoteType } from '@app/modules/map-module/components/map/notes/types';

const normalizeMapNoteInput = (note: MapNoteType): MapNoteInput => {
  const {
    title,
    id,
    place,
    road: { id: roadId },
    message,
    photos,
    dateTime,
    visibility,
    address,
  } = note;

  return { title, id, place, roadId, message, photos, dateTime, visibility, address };
};

export default normalizeMapNoteInput;
